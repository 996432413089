import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getList } from '../../services/admin'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import {
  Button,
  FormGroup,
  TextField,
  withStyles,
  Chip,
} from '@material-ui/core'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { isMobile } from 'react-device-detect'


const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '74%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 220  },
  { field: 'email', headerName: 'Email',width: 220  },
  {
    field: 'access',
    headerName: 'Access',
    width: 620,
    renderCell: (params) => {
      let access = []
      Object.entries(params.row.access.control).forEach(([key, item]) => {
        if (item) {
          access.push(<Chip key={key} label={key.toLocaleUpperCase()} />)
        }
      })

      return access
    },
  },
  {
    field: 'notifications',
    headerName: 'Notification',
    width: 320,
    renderCell: (params) => {
      let notifications = []
      Object.entries(params.row.access.notifications).forEach(([key, item]) => {
        if (item) {
          notifications.push(<Chip key={key} label={key.toLocaleUpperCase()} />)
        }
      })

      return notifications
    },
  },
]

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState(null)
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25)
  const [loading, setLoading] = useState(true)
  const [filterField, setFilterField] = useState('')
  const searchRef = useRef(null)

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 25,
    })
  }, [])

  const updatePageHandler = (pageInfo) => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    })
  }

  const loadData = (payload) => {
    setLoading(true)
    getList(token, {
      ...payload,
      'user-specific': false,
    }).then((res) => {
      const summary = []
      res.collection.forEach((item) => {
        summary.push({
          id: item.raw.id,
          ...item.data,
        })
      })
      setRows(summary)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      setLoading(false)
    })
  }

  const searchHandler = () => {
    loadData({
      fields: {
        email: searchRef.current.value,
      },
      'current-page': 1,
      'record-per-page': 25,
    })
  }

  const handleRecordSelect = (row) => {
    navigate(`/users/${row.id}/information`)
  }

  if (!rows) {
    return null
  }


  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h1>User Management</h1>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormGroup row style={{ marginTop: '20px' }}>
              <StyledTextField
                variant="outlined"
                placeholder="Search Email"
                inputRef={searchRef}
              />
              <StyledButton
                variant="contained"
                disableElevation
                onClick={() => searchHandler()}
              >
                Search
              </StyledButton>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                navigate('/users/create')
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
              }}
              fullWidth={isMobile}
            >
              Create
            </Button>
          </Grid>
        </Grid>

        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageInfoRowPerPage,
                  page: pageInfoCurrentPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            rowCount={pageInfoTotalRows}
            loading={loading}
            onPaginationModelChange={(newPage) => updatePageHandler(newPage)}
            disableColumnFilter
            disableColumnMenu
            onRowClick={handleRecordSelect}
          />
        </div>
      </Container>
    </>
  )
}

export default App
