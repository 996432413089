import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import Container from '@mui/material/Container'

const App = () => {
  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <div style={{ paddingTop: '50px', textAlign: 'center' }}>
            <img src="/logo.png" style={{width: '300px'}} />
            <h1>Welcome to Store2DoorDirect Application</h1>
          </div>
        </div>
      </Container>
    </>
  )
}

export default App
