import React, { useState, useContext, useEffect } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getInformation } from '../../services/product'
import { UserContext } from '../../context/UserProvider.jsx'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  Container,
  Paper,
  Button,
} from '@mui/material'
import { useParams } from 'react-router-dom'

const App = () => {
  const navigate = useNavigate();
  const [itemData, setItemData] = useState(null)
  const { token } = useContext(UserContext)
  const { id } = useParams()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    getInformation(token, id)
      .then((res) => {
        setItemData(res.data)
      })
      .catch((err) => {
        alert(err.response.data.message)
      })
  }

  if (!itemData) {
    return null
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h1>Product Information</h1>
          </Grid>
          <Grid item xs={8}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                navigate(-1);
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
              }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <Paper elevation={0} sx={{ padding: 3 }}>
            <InformationView itemData={itemData} />
          </Paper>
        </div>
      </Container>
    </>
  )
}

const InformationView = ({ itemData }) => {
  return (
    <>
      <table className="table-information">
        <tbody>
          <tr>
            <td className="caption">
              <strong>Product Number: </strong>
            </td>
            <td>{itemData['item-number'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Product Name: </strong>
            </td>
            <td>{itemData['name'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Category: </strong>
            </td>
            <td>{itemData['category'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Bin Number </strong>
            </td>
            <td>{itemData['bin'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>UPC</strong>
            </td>
            <td>{itemData['upc'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Image: </strong>
            </td>
            <td>
              <div style={{width: '100%', textAlign: 'center', height: '100px'}}>
                  <img src={itemData['image']} style={{width: '100px', height: '100px'}}/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default App
