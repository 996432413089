import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getList } from '../../services/customer'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Button, FormGroup, TextField, withStyles } from '@material-ui/core'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { isMobile } from 'react-device-detect';

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '74%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'account-number', headerName: 'Account Number', flex: 1 },
  { field: 'company-name', headerName: 'Company Name', flex: 1 },
  { field: 'email', headerName: 'Email Address', flex: 1 },
  { field: 'phone', headerName: 'Phone Number', flex: 1 },
  {
    field: 'sales-person',
    headerName: 'Sales Person',
    flex: 1,
    renderCell: (params) => {
      let name = params.row['sales-person'].label ?? ''
      return <>{name}</>
    },
  },
]

const columnsMobile = [
  { field: 'id', headerName: 'ID', width: 170 },
  { field: 'account-number', headerName: 'Account Number', width: 170  },
  { field: 'company-name', headerName: 'Company Name', width: 370  },
  { field: 'email', headerName: 'Email Address', width: 270  },
  { field: 'phone', headerName: 'Phone Number', width: 270  },
  {
    field: 'sales-person',
    headerName: 'Sales Person',
    width: 170 ,
    renderCell: (params) => {
      let name = params.row['sales-person'].label ?? ''
      return <>{name}</>
    },
  },
]

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25)
  const [loading, setLoading] = useState(true)
  const [filterField, setFilterField] = useState('')
  const searchRef = useRef(null)

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 25,
    })
  }, [])

  const updatePageHandler = (pageInfo) => {
    loadData({
      fields: {
        [filterField]: searchRef.current.value,
      },
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    })
  }

  const loadData = (payload) => {
    setLoading(true)
    getList(token, {
      ...payload,
      'user-specific': false,
    }).then((res) => {
      const summary = []
      res.collection.forEach((item) => {
        summary.push({
          id: item.raw.id,
          ...item.data,
        })
      })
      setRows(summary)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      setLoading(false)
    })
  }

  const searchHandler = () => {
    if (filterField === '') {
      return alert('Please select a field to search')
    }

    loadData({
      fields: {
        [filterField]: searchRef.current.value,
      },
      'current-page': 1,
      'record-per-page': 25,
    })
  }

  const handleRecordSelect = (row) => {
    navigate(`/customers/${row.id}/information`)
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h1>Customers</h1>
          </Grid>
          <Grid item xs={12} md={3}>
            <div style={{ marginTop: '20px' }}>
              <Select
                options={[
                  { value: 'account-number', label: 'Account Number' },
                  { value: 'company-name', label: 'Company Name' },
                  { value: 'email', label: 'Email Address' },
                  { value: 'sales-person', label: 'Sales Person' },
                ]}
                onChange={(e) => setFilterField(e.value)}
                placeholder="Filter by"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    background: '#fff',
                    borderColor: '#9e9e9e',
                    height: '55px',
                    boxShadow: state.isFocused ? null : null,
                  }),

                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                    padding: '0 6px',
                  }),

                  input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                  }),
                  indicatorSeparator: (state) => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup row style={{ marginTop: '20px' }}>
              <StyledTextField
                variant="outlined"
                placeholder="Search"
                inputRef={searchRef}
              />
              <StyledButton
                variant="contained"
                disableElevation
                onClick={() => searchHandler()}
              >
                Search
              </StyledButton>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                navigate('/customers/create')
              }}
              style={isMobile ? {
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                width: '100%'
              } : {
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid>

        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rows={rows}
            columns={isMobile ? columnsMobile : columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageInfoRowPerPage,
                  page: pageInfoCurrentPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            rowCount={pageInfoTotalRows}
            loading={loading}
            onPaginationModelChange={(newPage) => updatePageHandler(newPage)}
            disableColumnFilter
            disableColumnMenu
            onRowClick={handleRecordSelect}
          />
        </div>
      </Container>
    </>
  )
}

export default App
