import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getList } from '../../services/product'
import {
  getItemList as geteOrderItems,
  getInfo as getOrderInformation
} from '../../services/order'
import { getInformation as getCustomerInformation } from '../../services/customer'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import { FormGroup, TextField, withStyles } from '@material-ui/core'
import { Button, Container, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '74%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)

const App = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25)
  const [loading, setLoading] = useState(true)
  const [filterField, setFilterField] = useState('')
  const searchRef = useRef(null)
  const [selected, setSelected] = useState([])
  const [orderInformation, setOrderInformation] = useState(null)

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 120,
      renderCell: (params) => {
        let url = params.row['image']
        return (
          <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
            <img src={url} style={{ width: '100px', height: '100px' }} />
          </div>
        )
      },
    },
    { field: 'item-number', headerName: 'Item Number', width: 120 },
    { field: 'name', headerName: 'Product Name', flex: 1 },
    { field: 'category', headerName: 'Category', width: 220 },
    { field: 'qty', headerName: 'Qty', width: 120 },
    { field: 'type', headerName: 'Type', width: 120 },
  ]
  const columnsMobile = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 220,
      renderCell: (params) => {
        let url = params.row['image']
        return (
          <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
            <img src={url} style={{ width: '100px', height: '100px' }} />
          </div>
        )
      },
    },
    { field: 'item-number', headerName: 'Item Number', width: 220 },
    { field: 'name', headerName: 'Product Name', width: 320 },
    { field: 'category', headerName: 'Category', width: 320 },
    { field: 'qty', headerName: 'Qty', width: 220 },
    { field: 'type', headerName: 'Type', width: 220 },
  ]
  

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 25,
    })
    loadInformation()
  }, [])

  const updatePageHandler = (pageInfo) => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    })
  }

  const loadInformation = () => {
    getOrderInformation(token, id).then((res) => {
      const customerId = res.data['customer-id']
      const orderId = res.data['order-number']
      const status = res.data['status']
      let dateCreate = new Date(res['created_last'])
      dateCreate = dateCreate.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      })

      getCustomerInformation(token, customerId).then((res) => {
        setOrderInformation({
          customerId: customerId,
          orderId: orderId,
          status: status,
          dateCreate: dateCreate,
          customerName: res.data['company-name'],
          customerAccount: res.data['account-number'],
        })
      })
    })
  }

  const loadData = (payload) => {
    setLoading(true)
    geteOrderItems(token, {
      'current-page': 1,
      'record-per-page': 'all',
      'user-specific': false,
      fields: {
        'order-id': id,
      },
    }).then((res) => {
      const summary = {}
      const productEntityIds = []
      const collection = res.collection ?? []
      collection.forEach((item) => {
        summary[parseInt(item.data['product-id'])] = item.data
        productEntityIds.push(parseInt(item.data['product-id']))
      })
      setSelected(summary)
      if (productEntityIds.length > 0) {
        loadProducts(
          {
            'current-page': 1,
            'record-per-page': 25,
            entities: productEntityIds,
            'user-specific': false,
            ...payload,
          },
          summary,
        )
      } else {
        setLoading(false)
      }
    })
  }

  const loadProducts = (payload, presetSummary = []) => {
    getList(token, payload).then((res) => {
      presetSummary = presetSummary ?? selected
      const summary = []
      res.collection.forEach((item) => {
        summary.push({
          id: item.raw.id,
          qty: presetSummary[item.raw.id]
            ? presetSummary[item.raw.id]['qty']
            : 0,
          type: presetSummary[item.raw.id]
            ? presetSummary[item.raw.id]['type'].toUpperCase()
            : '',
          ...item.data,
        })
      })
      setRows(summary)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      setLoading(false)
    })
  }

  const searchHandler = () => {
    if (filterField === '') {
      return alert('Please select a field to search')
    }

    loadData({
      fields: {
        [filterField]: searchRef.current.value,
      },
    })
  }

  const handleRecordSelect = (productId) => {
    navigate(`/products/${productId}/information`)
  }

  const downloadHandler = () => {
    window.open(
      `${process.env.REACT_APP_API}/order/download/${id}`,
      '_blank',
    )
  }

  if (!orderInformation) {
    return <div>Loading...</div>
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <h1>Order Information</h1>
          </Grid>
          <Grid item xs={12}  md={3}>
            <div style={{ marginTop: '20px' }}>
              <Select
                options={[
                  { value: 'item-number', label: 'Item Number' },
                  { value: 'name', label: 'Product Name' },
                  { value: 'category', label: 'Category' },
                ]}
                onChange={(e) => setFilterField(e.value)}
                placeholder="Filter by"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    background: '#fff',
                    borderColor: '#9e9e9e',
                    height: '55px',
                    boxShadow: state.isFocused ? null : null,
                  }),

                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                    padding: '0 6px',
                  }),

                  input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                  }),
                  indicatorSeparator: (state) => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}  md={4}>
            <FormGroup row style={{ marginTop: '20px' }}>
              <StyledTextField
                variant="outlined"
                placeholder="Search"
                inputRef={searchRef}
              />
              <StyledButton
                variant="contained"
                disableElevation
                onClick={() => searchHandler()}
              >
                Search
              </StyledButton>
            </FormGroup>
          </Grid>
          <Grid item xs={12}  md={2}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => downloadHandler()}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
                marginLeft: '10px',
              }}
              fullWidth={isMobile}
            >
              Download
            </Button>

            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                navigate(-1)
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
              }}
              fullWidth={isMobile}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <table className="table-information">
            <tbody>
              <tr>
                <td className="caption">
                  <strong>Order Number: </strong>
                </td>
                <td>{orderInformation.orderId ?? ''}</td>
              </tr>
              <tr>
                <td className="caption">
                  <strong>Account Number: </strong>
                </td>
                <td>{orderInformation.customerAccount ?? ''}</td>
              </tr>
              <tr>
                <td className="caption">
                  <strong>Company Name: </strong>
                </td>
                <td>{orderInformation.customerName ?? ''}</td>
              </tr>
              <tr>
                <td className="caption">
                  <strong>Status: </strong>
                </td>
                <td>{orderInformation.status.toUpperCase() ?? ''}</td>
              </tr>
              <tr>
                <td className="caption">
                  <strong>Order Date: </strong>
                </td>
                <td>{orderInformation.dateCreate ?? ''}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            height: '70vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rows={rows}
            columns={isMobile ? columnsMobile : columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageInfoRowPerPage,
                  page: pageInfoCurrentPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            rowCount={pageInfoTotalRows}
            loading={loading}
            onPaginationModelChange={(newPage) => updatePageHandler(newPage)}
            disableColumnFilter
            disableColumnMenu
            rowHeight={125}
            onRowClick={(params) => handleRecordSelect(params.row.id)}
          />
        </div>
      </Container>
    </>
  )
}

export default App
