import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getList } from '../../services/product'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Button, FormGroup, TextField, withStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { isMobile } from 'react-device-detect'

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '74%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'image',
    headerName: 'Image',
    width: 120,
    renderCell: (params) => {
      let url = params.row['image']
      return (
        <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
          <img src={url} style={{ width: '100px', height: '100px' }} />
        </div>
      )
    },
  },
  { field: 'item-number', headerName: 'Item Number', width: 120 },
  { field: 'name', headerName: 'Product Name', flex: 1 },
  { field: 'category', headerName: 'Category', width: 220 },
  { field: 'bin', headerName: 'Bin Number', width: 120 },
  { field: 'upc', headerName: 'UPC', width: 120 },
]

const columnsMobile = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'image',
    headerName: 'Image',
    width: 220,
    renderCell: (params) => {
      let url = params.row['image']
      return (
        <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
          <img src={url} style={{ width: '100px', height: '100px' }} />
        </div>
      )
    },
  },
  { field: 'item-number', headerName: 'Item Number', width: 220, },
  { field: 'name', headerName: 'Product Name', width: 320,},
  { field: 'category', headerName: 'Category', width: 320 },
  { field: 'bin', headerName: 'Bin Number', width: 220 },
  { field: 'upc', headerName: 'UPC', width: 220 },
]

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(100)
  const [loading, setLoading] = useState(true)
  const [filterField, setFilterField] = useState('')
  const searchRef = useRef(null)

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 100,
      'user-specific': false
    })
  }, [])

  const updatePageHandler = (pageInfo) => {
    let payload = {
      fields: {
        [filterField]: searchRef.current.value,
      },
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
      'user-specific': false
    };

    if (searchRef.current.value === '') {
      payload = {
        'current-page': pageInfo.page + 1,
        'record-per-page': pageInfo.pageSize,
        'user-specific': false
      }
    }

    loadData(payload);
  }

  const loadData = (payload) => {
    setLoading(true)
    getList(token, payload).then((res) => {
      const summary = []
      res.collection.forEach((item) => {
        summary.push({
          id: item.raw.id,
          ...item.data,
        })
      })
      setRows(summary)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      setLoading(false)
    })
  }

  const searchHandler = () => {
    if (filterField === '') {
      return alert('Please select a field to search')
    }

    if (searchRef.current.value === '') {
      window.location.reload();
    }

    loadData({
      fields: {
        [filterField]: searchRef.current.value,
      },
      'current-page': 1,
      'record-per-page': 100,
      'user-specific': false
    })
  }

  const handleRecordSelect = (row) => {
    navigate(`/products/${row.id}/information`)
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h1>Products</h1>
          </Grid>
          <Grid item xs={12} md={3}>
            <div style={{ marginTop: '20px' }}>
              <Select
                options={[
                  { value: 'item-number', label: 'Item Number' },
                  { value: 'name', label: 'Product Name' },
                  { value: 'category', label: 'Category' },
                  { value: 'bin', label: 'Bin Number' },
                  { value: 'upc', label: 'UPC' },
                ]}
                onChange={(e) => setFilterField(e.value)}
                placeholder="Filter by"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    background: '#fff',
                    borderColor: '#9e9e9e',
                    height: '55px',
                    boxShadow: state.isFocused ? null : null,
                  }),

                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                    padding: '0 6px',
                  }),

                  input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                  }),
                  indicatorSeparator: (state) => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormGroup row style={{ marginTop: '20px' }}>
              <StyledTextField
                variant="outlined"
                placeholder="Search"
                inputRef={searchRef}
              />
              <StyledButton
                variant="contained"
                disableElevation
                onClick={() => searchHandler()}
              >
                Search
              </StyledButton>
            </FormGroup>
          </Grid>
        </Grid>

        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rows={rows}
            columns={isMobile ? columnsMobile : columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageInfoRowPerPage,
                  page: pageInfoCurrentPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            rowCount={pageInfoTotalRows}
            loading={loading}
            onPaginationModelChange={(newPage) => updatePageHandler(newPage)}
            disableColumnFilter
            disableColumnMenu
            onRowClick={handleRecordSelect}
            rowHeight={125}
          />
        </div>
      </Container>
    </>
  )
}

export default App
