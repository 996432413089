import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/NavbarCustomer'
import { updatePassword } from '../../services/customer'
import { UserContext } from '../../context/UserProvider.jsx'
import { TextField, Grid, Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Container } from '@mui/material'
import { isMobile } from 'react-device-detect';

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const onSubmit = () => {
    if (currentPassword === null || newPassword === null || confirmPassword === null) {
      alert('Please fill in all fields');
      return;
    }

    if (newPassword !== confirmPassword) {
      alert('New password and confirm password do not match');
      return;
    }

    if (newPassword === currentPassword) {
      alert('New password cannot be the same as current password');
      return;
    }

    if (newPassword.length < 8) {
      alert('New password must be at least 8 characters');
      return;
    }

    if (newPassword.length > 20) {
      alert('New password must be at most 20 characters');
      return;
    }

    if (!newPassword.match(/[a-z]/g)) {
      alert('New password must contain at least one lowercase letter');
      return;
    }

    if (!newPassword.match(/[A-Z]/g)) {
      alert('New password must contain at least one uppercase letter');
      return;
    }

    if (!newPassword.match(/[0-9]/g)) {
      alert('New password must contain at least one number');
      return;
    }

    if (!newPassword.match(/[^a-zA-Z\d]/g)) {
      alert('New password must contain at least one special character');
      return;
    }

    updatePassword(token, {
      'password': currentPassword,
      'new-password': newPassword,
    }).then(res => {
      if (res['status'] === 'success') {
        alert('Password updated successfully');
        navigate('/');
      } else {
        alert('Invalid current password');
      }
    });
  }
 
  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h1>Update Password</h1>
          </Grid>
        </Grid>
        <div
          style={{
            height: '80vh',
            width: '90%',
            backgroundColor: '#ffff',
            marginTop: '20px',
            padding: '20px',
          }}
        >
              <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextField
                id="outlined-basic"
                label="Current Password"
                variant="outlined"
                style={isMobile ? { width: '90%', marginTop: '20px' } : { width: '50%', marginTop: '20px' }}
                onChange={(e) => setCurrentPassword(e.target.value)}
                type='password'
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                id="outlined-basic"
                label="New Password"
                variant="outlined"
                style={isMobile ? { width: '90%', marginTop: '20px' } : { width: '50%', marginTop: '20px' }}
                onChange={(e) => setNewPassword(e.target.value)}
                type='password'
            />
             </Grid>
            <Grid item xs={12}>
            <TextField
                id="outlined-basic"
                label="Confirm Password"
                variant="outlined"
                style={isMobile ? { width: '90%', marginTop: '20px' } : { width: '50%', marginTop: '20px' }}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type='password'
            />
             </Grid>
            <Grid item xs={12}>
            <Button
                style={{
                    backgroundColor: '#ff0000',
                    color: '#fff',
                    width: '50%',
                    height: '50px',
                    marginTop: '20px',
                }} 
                onClick={() => onSubmit()}
            >
                Update
            </Button>
            </Grid>
            </Grid>
          
        </div>
      </Container>
    </>
  )
}

export default App
