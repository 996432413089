import React from 'react';
import './style.css'; // Import your CSS file for styling

const LoadingScreen = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-spinner"></div>
      {/* You can add a loading message here if you want */}
      {/* <p>Loading...</p> */}
    </div>
  );
};

export default LoadingScreen;
