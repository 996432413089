import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { FormGroup, TextField, withStyles } from '@material-ui/core'
import { Button, Container, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/UserProvider.jsx'
import { getList as geteOrders } from '../../services/order'
import { getList as getCustomerList } from '../../services/customer'
import { DataGrid } from '@mui/x-data-grid'
import { isMobile } from 'react-device-detect'
import { DateRangePicker } from 'react-date-range'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ReplayIcon from '@mui/icons-material/Replay';

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '74%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25)
  const [loading, setLoading] = useState(true)
  const [filterField, setFilterField] = useState('order-number')
  const searchRef = useRef(null)
  const [customerOptions, setCustomerOptions] = useState({})
  const [showDateRange, setShowDateRange] = useState(false)
  const [dateRange, setDateRange] = useState({
    startDate: new Date("Jan 1, 2023"),
    endDate: new Date(),
    key: 'selection',
  })
  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'order-number', headerName: 'Order Number', flex: 1 },
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 1,
      renderCell: (params) => {
        return (
            <div style={{ display: 'block' }}>
                {customerOptions[params.row['customer-id']].account ?? ''} - {customerOptions[params.row['customer-id']].name ?? ''}
            </div>
        )
      },
    },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'dateUpdated', headerName: 'Order Date', flex: 1 },
  ]
  const columnsMobile = [
    { field: 'id', headerName: 'ID', width: 170 },
    { field: 'order-number', headerName: 'Order Number', width: 270 },
    {
      field: 'customer',
      headerName: 'Customer',
      width: 270,
      renderCell: (params) => {
        return (
            <div style={{ display: 'block' }}>
                {customerOptions[params.row['customer-id']].account ?? ''} - {customerOptions[params.row['customer-id']].name ?? ''}
            </div>
        )
      },
    },
    { field: 'status', headerName: 'Status', width: 270 },
    { field: 'dateUpdated', headerName: 'Order Date', width: 270 },
  ]
  

  useEffect(() => {
    loadCutomers()
  }, [])

  const loadCutomers = () => {
    getCustomerList(token, {
      'user-specific': false,
      'record-per-page': 'all',
    }).then((res) => {
      const options = {}
      res.collection.forEach((item) => {
        options[item.raw.id] = {
            name: item.data['company-name'],
            account: item.data['account-number'],
        }
      })
      setCustomerOptions(options)
      load()
    })
  }

  const updatePageHandler = (pageInfo) => {
    load({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    })
  }

  const load = (payload) => {
    setLoading(true)
    geteOrders(token, {
      ...payload,
      'user-specific': false,
    }).then((res) => {
      const summary = []

      res.collection.forEach((item) => {
        const status = item.data.status
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')

        let dateCreate = new Date(item.raw['created_last'])
        dateCreate = dateCreate.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })

        summary.push({
          ...item.data,
          id: item.raw.id,
          status: status,
          dateUpdated: dateCreate,
        })
      })

      setRows(summary)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      setLoading(false)
    })
  }

  const searchHandler = () => {
    if (filterField === '') {
      return alert('Please select a field to search')
    }

    const dateStartFomat = dateRange.startDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    const dateEndFomat = dateRange.endDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })

    let fieldsToSearch = {
      'current-page': 1,
      'record-per-page': 25,
      'date-filter': {
        start: dateStartFomat,
        end: dateEndFomat,
      },
    };

    if (searchRef.current.value !== '') {
        fieldsToSearch = {
          ...fieldsToSearch,
          fields: {
            [filterField]: searchRef.current.value
          },
        }
    }

    load(fieldsToSearch)
  }

  const handleRecordSelect = (row) => {
    navigate(`/orders/${row.id}/information`)
  }

  return (
    <>
     {showDateRange && (
        <div
          style={{
            position: 'absolute',
            zIndex: '1000',
            backgroundColor: 'rgb(94 94 94 / 85%)',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            paddingTop: '30px',
          }}
        >
          <DateRangePicker
            ranges={[dateRange]}
            onChange={(e) => {
              setDateRange(e.selection)
            }}
          />
          <br />
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              searchHandler()
              setShowDateRange(false)
            }}
            style={{
              marginTop: '20px',
              backgroundColor: '#3675af',
              color: 'white',
              height: '55px',
              width: '300px',
            }}
          >
            Filter
          </Button>
        </div>
      )}
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h1>Orders</h1>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                window.location.reload()
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
                marginLeft: '20px',
              }}
              fullWidth={isMobile}
            >
              <ReplayIcon />
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setShowDateRange(true)
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
              }}
              fullWidth={isMobile}
            >
              <CalendarMonthIcon />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup row style={{ marginTop: '20px' }}>
              <StyledTextField
                variant="outlined"
                placeholder="Search Order Number"
                inputRef={searchRef}
              />
              <StyledButton
                variant="contained"
                disableElevation
                onClick={() => searchHandler()}
              >
                Search
              </StyledButton>
            </FormGroup>
          </Grid>
         
        </Grid>

        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rows={rows}
            columns={isMobile ? columnsMobile : columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageInfoRowPerPage,
                  page: pageInfoCurrentPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            rowCount={pageInfoTotalRows}
            loading={loading}
            onPaginationModelChange={(newPage) => updatePageHandler(newPage)}
            disableColumnFilter
            disableColumnMenu
            onRowClick={handleRecordSelect}
          />
        </div>
      </Container>
    </>
  )
}

export default App
