import React, { useState } from 'react'
import './style.css'

function Quantity({defaultValue = 1, onChange, min = 1}) {
  const [value, setValue] = useState(defaultValue);

    React.useEffect(() => {
        onChange(value);
    }, [value]);


  const increment = () => {
    setValue((prevValue) => prevValue + 1)
  }

  const decrement = () => {
    if (value > min) {
        setValue((prevValue) => prevValue - 1)
    } else {
        setValue(min);
    }
  }

  return (
    <div className="quantity-input">
      <button
        className="quantity-input__modifier quantity-input__modifier--left"
        onClick={decrement}
      >
        &mdash;
      </button>
      <input
        className="quantity-input__screen"
        type="text"
        value={value}
        readOnly
      />
      <button
        className="quantity-input__modifier quantity-input__modifier--right"
        onClick={increment}
      >
        &#xff0b;
      </button>
    </div>
  )
}

export default Quantity
