import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import LoadingScreen from '../../components/LoadingScreen'
import { getList } from '../../services/product'
import {
  create as addPreset,
  batchCreate as batchAddPreset,
  getList as presetList,
  remove as removePreset,
} from '../../services/preset'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Button, FormGroup, TextField, withStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { isMobile } from 'react-device-detect'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '74%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25)
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingBatch, setLoadingBatch] = useState(false)
  const [filterField, setFilterField] = useState('')
  const [initBatch, setInitBatch] = useState(false)
  const searchRef = useRef(null)
  const batchRef = useRef(null)
  const { customerId } = useParams()
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 120,
      renderCell: (params) => {
        let url = params.row['image']
        return (
          <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
            <img src={url} style={{ width: '100px', height: '100px' }} />
          </div>
        )
      },
    },
    { field: 'item-number', headerName: 'Item Number', width: 120 },
    { field: 'name', headerName: 'Product Name', flex: 1 },
    { field: 'category', headerName: 'Category', width: 220 },
    { field: 'bin', headerName: 'Bin Number', width: 120 },
    { field: 'upc', headerName: 'UPC', width: 120 },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ display: 'block' }}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                handleRecordSelect(params.row['id'])
              }}
              style={{
                marginTop: '10px',
                backgroundColor: '#3675af',
                color: 'white',
                width: '100%',
                float: 'left',
              }}
            >
              View
            </Button>

            {selected[params.row['id']] ? (
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  handleRemove(params.row['id'])
                }}
                style={{
                  marginTop: '10px',
                  backgroundColor: '#3675af',
                  color: 'white',
                  width: '100%',
                  float: 'left',
                  backgroundColor: 'red',
                }}
              >
                Remove
              </Button>
            ) : (
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  handleAdd(params.row['id'], customerId)
                }}
                style={{
                  marginTop: '10px',
                  backgroundColor: '#3675af',
                  color: 'white',
                  width: '100%',
                  float: 'left',
                }}
              >
                Add
              </Button>
            )}
          </div>
        )
      },
    },
  ]
  const columnsMobile = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 220,
      renderCell: (params) => {
        let url = params.row['image']
        return (
          <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
            <img src={url} style={{ width: '100px', height: '100px' }} />
          </div>
        )
      },
    },
    { field: 'item-number', headerName: 'Item Number', width: 220 },
    { field: 'name', headerName: 'Product Name', width: 320 },
    { field: 'category', headerName: 'Category', width: 320 },
    { field: 'bin', headerName: 'Bin Number', width: 220 },
    { field: 'upc', headerName: 'UPC', width: 220 },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ display: 'block' }}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                handleRecordSelect(params.row['id'])
              }}
              style={{
                marginTop: '10px',
                backgroundColor: '#3675af',
                color: 'white',
                width: '100%',
                float: 'left',
              }}
            >
              View
            </Button>

            {selected[params.row['id']] ? (
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  handleRemove(params.row['id'])
                }}
                style={{
                  marginTop: '10px',
                  backgroundColor: '#3675af',
                  color: 'white',
                  width: '100%',
                  float: 'left',
                  backgroundColor: 'red',
                }}
              >
                Remove
              </Button>
            ) : (
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  handleAdd(params.row['id'], customerId)
                }}
                style={{
                  marginTop: '10px',
                  backgroundColor: '#3675af',
                  color: 'white',
                  width: '100%',
                  float: 'left',
                }}
              >
                Add
              </Button>
            )}
          </div>
        )
      },
    },
  ]
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 25,
    })
    loadPreset()
  }, [])

  const updatePageHandler = (pageInfo) => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    })
  }

  const loadData = (payload) => {
    setLoading(true)
    let operator = '=';
    
    if (filterField === 'category' || filterField === 'name') {
      operator = 'like';
    }

    getList(token, {
      'operator': operator,
      ...payload,
      'user-specific': false,
    }).then((res) => {
      const summary = []
      res.collection.forEach((item) => {
        summary.push({
          id: item.raw.id,
          ...item.data,
        })
      })
      setRows(summary)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      setLoading(false)
    })
  }

  const searchHandler = () => {
    if (filterField === '') {
      return alert('Please select a field to search')
    }

    loadData({
      fields: {
        [filterField]: searchRef.current.value,
      },
      'current-page': 1,
      'record-per-page': 25,
    })
  }

  const handleRecordSelect = (id) => {
    navigate(`/products/${id}/information`)
  }

  const handleAdd = (id, customerId) => {
    setLoading(true);
    addPreset(token, {
      'product-id': id,
      'customer-id': customerId,
    }).then((res) => {
      loadPreset()
    })
  }

  const handleRemove = (id) => {
    setLoading(true);
    removePreset(token, selected[id]).then((res) => {
      loadPreset()
    })
  }

  const loadPreset = () => {
    setLoading(true);
    presetList(token, {
      'record-per-page': 'all',
      'user-specific': false,
      'operator': '=',
      fields: {
        'customer-id': customerId,
      },
    }).then((res) => {
      const summary = {}
      res.collection.forEach((item) => {
        summary[parseInt(item.data['product-id'])] = item.raw.id
      })
      setSelected(summary)
      setLoading(false);
    })
  }

  const handleBatchAdd = () => {
    const confirm = window.confirm('Are you sure you want to add these products?');
    
    if (!confirm) {
      return;
    }

    const batch = batchRef.current.value;
    const batchArray = batch.split(/[\s,]+/);

    if (batchArray.length === 0) {
      return alert('Please enter at least one item number');
    }

    setLoadingBatch(true);
    batchAddPreset(token, {
      'product-ids': batchArray,
      'customer-id': customerId,
    }).then((res) => {
      setSummary(true);
      setInitBatch(false);
      loadPreset();
      setLoadingBatch(false);
    });
  }

  return (
    <>
      {summary &&      
      <Modal
        open={summary ? true : false}
        onClose={() => setSummary(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Batch Upload Message
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Preset creation is being process in the background. Items will be added to the preset once the process is complete.        
          </Typography>
          <Button
            variant="contained"
            disableElevation
            onClick={() => setSummary(null)}
            style={{
              marginTop: '20px',
              backgroundColor: '#3675af',
              color: 'white',
              height: '55px',
              marginRight: '10px',
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    }

      {loadingBatch && <LoadingScreen />}
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <h1>Products</h1>
          </Grid>
          <Grid item xs={12} md={3}>
            <div style={{ marginTop: '20px' }}>
              <Select
                options={[
                  { value: 'item-number', label: 'Item Number' },
                  { value: 'name', label: 'Product Name' },
                  { value: 'category', label: 'Category' },
                  { value: 'bin', label: 'Bin Number' },
                  { value: 'upc', label: 'UPC' },
                ]}
                onChange={(e) => setFilterField(e.value)}
                placeholder="Filter by"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    background: '#fff',
                    borderColor: '#9e9e9e',
                    height: '55px',
                    boxShadow: state.isFocused ? null : null,
                  }),

                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                    padding: '0 6px',
                  }),

                  input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                  }),
                  indicatorSeparator: (state) => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup row style={{ marginTop: '20px' }}>
              <StyledTextField
                variant="outlined"
                placeholder="Search"
                inputRef={searchRef}
              />
              <StyledButton
                variant="contained"
                disableElevation
                onClick={() => searchHandler()}
              >
                Search
              </StyledButton>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                navigate(-1)
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
              }}
            >
              Back
            </Button>
            {!initBatch && (
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setInitBatch(true)
                }}
                style={{
                  marginTop: '20px',
                  backgroundColor: '#3675af',
                  color: 'white',
                  height: '55px',
                  float: 'right',
                  marginRight: '10px',
                }}
              >
                Batch
              </Button>
            )}
          </Grid>

          {initBatch && (
            <Grid item xs={12}>
              <div
                style={{
                  width: isMobile ? '90%' : '100%',
                  backgroundColor: '#ffff',
                  marginTop: '20px',
                  padding: '20px',
                }}
              >
                <strong>Batch Product Add</strong>
                <p>Add the Item Number seperated by comma, new line or space</p>
                <TextField
                  style={{ width: '100%' }}
                  multiline
                  minRows={4}
                  variant="outlined"
                  placeholder='Example: "123456, 123457, 123458" or "123456 123457 123458"'
                  inputRef={batchRef}
                />
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => handleBatchAdd()}
                  style={{
                    marginTop: '20px',
                    backgroundColor: '#3675af',
                    color: 'white',
                    height: '55px',
                    marginRight: '10px',
                  }}
                >
                  Apply
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    setInitBatch(false)
                  }}
                  style={{
                    marginTop: '20px',
                    backgroundColor: '#3675af',
                    color: 'white',
                    height: '55px',
                    marginRight: '10px',
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          )}
        </Grid>

        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rows={rows}
            columns={isMobile ? columnsMobile: columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageInfoRowPerPage,
                  page: pageInfoCurrentPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            rowCount={pageInfoTotalRows}
            loading={loading}
            onPaginationModelChange={(newPage) => updatePageHandler(newPage)}
            disableColumnFilter
            disableColumnMenu
            rowHeight={125}
          />
        </div>
      </Container>
    </>
  )
}

export default App
