import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonGroup } from '@mui/material'
import { useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

const styles = {
  buttonMobile: {
    width: '100%',
    marginTop: '10px',
  }
};

const App = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  if (isMobile) {
    return (
      <>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => navigate(`/customers/${id}/information`)}
          style={styles.buttonMobile}
        >
          Information
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => navigate(`/customers/${id}/products`)}
          style={styles.buttonMobile}
        >
          Products
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => navigate(`/customers/${id}/orders`)}
          style={styles.buttonMobile}
        >
          Orders
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => navigate(`/customers/${id}/invoices`)}
          style={styles.buttonMobile}
        >
          Invoices
        </Button>
      </>
    )
  }

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        style={{ marginTop: '30px', float: 'right' }}
      >
        <Button onClick={() => navigate(`/customers/${id}/information`)}>
          Information
        </Button>
        <Button onClick={() => navigate(`/customers/${id}/products`)}>
          Products
        </Button>
        <Button onClick={() => navigate(`/customers/${id}/orders`)}>
          Orders
        </Button>
        <Button onClick={() => navigate(`/customers/${id}/invoices`)}>
          Invoices
        </Button>
      </ButtonGroup>
    </>
  )
}

export default App
