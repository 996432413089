import axios from 'axios';


export const generate = async(token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
    };

    const formData = new FormData();

    for (const key in payload) {
        formData.append(key, payload[key]);
    }

    return axios.post(`${process.env.REACT_APP_API}/invoice/generate`, formData, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            alert('Customer ID not found or not registered');
        }

        return error.response;
    });
}

export const create = (token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.post(`${process.env.REACT_APP_API}/invoice-management`, payload, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}

export const getList = (token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    for (const key in payload) {
        if (payload[key] === '') {
            delete payload[key];
        }
    }

    return axios.post(`${process.env.REACT_APP_API}/invoice-management/list`, payload, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}

export const getInfo = (token, id) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.get(`${process.env.REACT_APP_API}/invoice-management/${id}`, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}

export const remove = (token, id) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.delete(`${process.env.REACT_APP_API}/invoice-management/${id}`, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}