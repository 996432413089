import axios from 'axios';
import md5 from 'md5';

export const login = (payload) => {
    payload.password = md5(payload.password);
    
    return axios.post(`${process.env.REACT_APP_API}/authenticate`, payload)
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        return error.response.status;
    });
}

export const getList = (token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.post(`${process.env.REACT_APP_API}/admin-management/list`, payload, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}

export const create = (token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    
    if (payload.password) {
        payload.password = md5(payload.password);
    } else {
        delete payload.password;
    }

    return axios.post(`${process.env.REACT_APP_API}/admin-management`, payload, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}


export const getInformation = (token, id) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.get(`${process.env.REACT_APP_API}/admin-management/${id}`, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}

export const update = (token, payload, id) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    if (payload.password) {
        payload.password = md5(payload.password);
    } else {
        delete payload.password;
    }

    return axios.patch(`${process.env.REACT_APP_API}/admin-management/${id}`, payload, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}
