import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { create } from '../../services/customer'
import { UserContext } from '../../context/UserProvider.jsx'
import { useNavigate } from 'react-router-dom'
import {
  TextField,
  Button,
  Grid,
  Container,
  Paper,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import Select from 'react-select'
import { getList } from '../../services/admin'

const Form = ({ onSubmit }) => {
  const { token } = useContext(UserContext)
  const [showPassword, setShowPassword] = useState(false)
  const [formData, setFormData] = useState({
    companyName: '',
    accountNumber: '',
    addressLine1: '',
    addressLine2: '',
    emailAddress: '',
    phoneNumber: '',
    password: '',
    salesPerson: null,
  })
  const [salesPersonList, setSalesPersonList] = useState([])

  useEffect(() => {
    getList(token, {
      'user-specific': false,
    }).then((res) => {
      let tempSalesPersonList = []
      res.collection.forEach((item) => {
        if (item.data.access.control['sales person'] === true) {
          tempSalesPersonList.push({
            value: item.raw.id,
            label: item.data.name,
          })
        }
      })

      setSalesPersonList(tempSalesPersonList)
    })
  }, [])

  const handleGeneratePassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8)
    setFormData((prevFormData) => ({
      ...prevFormData,
      password: randomPassword,
    }))
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleSelectChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      salesPerson: event,
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(formData)
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              required
              options={salesPersonList}
              onChange={handleSelectChange}
              placeholder="Sales Person *"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  background: '#fff',
                  borderColor: '#9e9e9e',
                  height: '55px',
                  boxShadow: state.isFocused ? null : null,
                }),

                valueContainer: (provided, state) => ({
                  ...provided,
                  height: '55px',
                  padding: '0 6px',
                }),

                input: (provided, state) => ({
                  ...provided,
                  margin: '0px',
                }),
                indicatorSeparator: (state) => ({
                  display: 'none',
                }),
                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  height: '55px',
                }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Account Number"
              name="accountNumber"
              value={formData.accountNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Address Line 1"
              name="addressLine1"
              value={formData.addressLine1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 2"
              name="addressLine2"
              value={formData.addressLine2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Email Address"
              name="emailAddress"
              type="email"
              value={formData.emailAddress}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleGeneratePassword}>
              Generate Password
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)

  const handleSubmit = (payload) => {
    create(token, {
      'company-name': payload.companyName,
      'account-number': payload.accountNumber,
      'address-line-1': payload.addressLine1,
      'address-line-2': payload.addressLine2,
      email: payload.emailAddress,
      phone: payload.phoneNumber,
      password: payload.password,
      'sales-person': payload.salesPerson,
    }).then((res) => {
      if (res.status && res.status === 405) {
        return alert(res.data.message)
      }

      alert('Customer created successfully')
      navigate('/customers')
    })
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h1>Create Customers</h1>
          </Grid>

          <Grid item xs={8}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                navigate('/customers')
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
              }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <Form onSubmit={handleSubmit} />
        </div>
      </Container>
    </>
  )
}

export default App
