import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import { UserContext } from '../context/UserProvider.jsx'
import LoginPage from './LoginPage'
import CustomerPage from './CustomerPage'
import CustomerCreatePage from './CustomerPage/create.jsx'
import CustomerInformationPage from './CustomerPage/information.jsx'
import CustomerPresetPage from './CustomerPage/preset.jsx'
import CustomerProductPage from './CustomerPage/product.jsx'
import CustomerOrderPage from './CustomerPage/order.jsx'
import NotFound from './NotFound'
import OrderPage from './OrderPage'
import ProductPage from './ProductPage'
import ProductInformationPage from './ProductPage/information.jsx'
import InvoicePage from './InvoicePage'
import CustomerPortal from './CustomerPortal'
import CustomerOrderPortal from './CustomerPortal/orders.jsx'
import CustomerOrderInfoPortal from './CustomerPortal/orderInfo.jsx'
import CustomerInvoicePortal from './CustomerPortal/invoices.jsx'
import CustomerInvoiceInfoPortal from './CustomerPortal/invoiceInfo.jsx'
import OrderInfoPortal from './OrderPage/orderInfo.jsx'
import InvoiceCreate from './InvoicePage/create.jsx'
import InvoiceInformation from './InvoicePage/information.jsx'
import CustomerInvoicesPage from './CustomerPage/invoices.jsx'
import UsersPage from './UserPage'
import UserCreatePage from './UserPage/create.jsx'
import UserInfoPage from './UserPage/information.jsx'
import UserUpdatePage from './UserPage/update.jsx'
import Dashboard from './Dashboard'
import CustomerChangePassword from './CustomerPortal/changePassword.jsx'

function Controller() {
  const { token, userType, access } = useContext(UserContext)

  const isAllowed = (accessKey) => {
    return access && access.control && access.control[accessKey.toLowerCase()]
  }

  if (!token) {
    return <LoginPage />
  }

  if (userType === 'admin') {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />

          {isAllowed('customers') && (
            <>
              <Route path="/customers" element={<CustomerPage />} />
              <Route
                path="/customers/create"
                element={<CustomerCreatePage />}
              />
              <Route
                path="/customers/:id/information"
                element={<CustomerInformationPage />}
              />
              <Route
                path="/customers/:id/invoices"
                element={<CustomerInvoicesPage />}
              />
              <Route
                path="/customers/:id/products"
                element={<CustomerPresetPage />}
              />
              <Route
                path="/customers/:customerId/products/add"
                element={<CustomerProductPage />}
              />
              <Route
                path="/customers/:id/orders"
                element={<CustomerOrderPage />}
              />
            </>
          )}

          {isAllowed('orders') && (
            <>
              <Route path="/orders" element={<OrderPage />} />
              <Route
                path="/orders/:id/information"
                element={<OrderInfoPortal />}
              />
            </>
          )}

          {isAllowed('products') && (
            <>
              <Route path="/products" element={<ProductPage />} />
              <Route
                path="/products/:id/information"
                element={<ProductInformationPage />}
              />
            </>
          )}

          {isAllowed('invoices') && (
            <>
              <Route path="/invoices" element={<InvoicePage />} />
              <Route path="/invoices/create" element={<InvoiceCreate />} />
              <Route
                path="/invoices/:id/information"
                element={<InvoiceInformation />}
              />
            </>
          )}

          {isAllowed('users') && (
            <>
              <Route path="/users" element={<UsersPage />} />
              <Route path="/users/create" element={<UserCreatePage />} />
              <Route path="/users/:id/information" element={<UserInfoPage />} />
              <Route path="/users/:id/update" element={<UserUpdatePage />} />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    )
  } else if (userType === 'customer') {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<CustomerPortal />} />
          <Route path="/products" element={<CustomerPortal />} />
          <Route path="/orders" element={<CustomerOrderPortal />} />
          <Route
            path="/orders/:id/information"
            element={<CustomerOrderInfoPortal />}
          />
          <Route path="/invoices" element={<CustomerInvoicePortal />} />
          <Route
            path="/invoices/:id/information"
            element={<CustomerInvoiceInfoPortal />}
          />
          <Route
            path="/change-password"
            element={<CustomerChangePassword />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    )
  }
}

export default Controller
