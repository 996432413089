import React, { createContext, useEffect, useState, useRef } from 'react'

const UserContext = createContext()

const UserProvider = ({ children }) => {
  const [token, setToken] = useState(null)
  const [userType, setUserType] = useState(null)
  const isMountedRef = useRef(false)
  const [access, setAccess] = useState(null)

  useEffect(() => {
    const tokenCache = localStorage.getItem('token')
    const userTypeCache = localStorage.getItem('userType')
    const accessCache = localStorage.getItem('access')


    if (tokenCache) {
      setToken(tokenCache)
    }

    if (userTypeCache) {
      setUserType(userTypeCache)
    }

    if (accessCache) {
      setAccess(JSON.parse(accessCache))
    }

    isMountedRef.current = true
  }, [])

  useEffect(() => {
    if (isMountedRef.current) {
      if (token) {
        localStorage.setItem('token', token)
      } else {
        localStorage.removeItem('token')
      }
    }
  }, [token])

  useEffect(() => {
    if (isMountedRef.current) {
      if (userType) {
        localStorage.setItem('userType', userType)
      } else {
        localStorage.removeItem('userType')
      }
    }
  }, [userType])


  useEffect(() => {
    console.log('access', access);
    if (isMountedRef.current) {
      if (access) {
        localStorage.setItem('access', JSON.stringify(access))
      } else {
        localStorage.removeItem('access')
      }
    }
  }, [access])

  const logout = () => {
    setToken(null)
  }

  return (
    <UserContext.Provider
      value={{
        token,
        setToken,
        logout,
        userType,
        setUserType,
        access,
        setAccess,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
export { UserContext }
