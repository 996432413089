import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/NavbarCustomer'
import { getList } from '../../services/product'
import {
  getList as presetList,
  remove as removePreset,
} from '../../services/preset'
import { getMyInformation } from '../../services/customer'
import { create as createOrder } from '../../services/order'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import { FormGroup, TextField, withStyles } from '@material-ui/core'
import { Button, Container, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import StarsIcon from '@mui/icons-material/Stars'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { isMobile } from 'react-device-detect'
import NumberQty from '../../components/NumberQty'

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '74%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)

const searchOptions = [
  { value: 'item-number', label: 'Item Number' },
  { value: 'name', label: 'Product Name' },
  { value: 'category', label: 'Category' },
]

const App = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(100)
  const [loading, setLoading] = useState(true)
  const [filterField, setFilterField] = useState('')
  const searchRef = useRef(null)
  const [selected, setSelected] = useState(null)
  const [cartItem, setCartItem] = useState([])
  const [cartItemQty, setCartItemQty] = useState(0)
  const [showMyCart, setShowMyCart] = useState(false)
  const [createProgress, setCreateProgress] = useState(false)
  const [showOnly, setShowOnly] = useState({
    value: 'my-list',
    label: 'Show My List',
  })
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 120,
      renderCell: (params) => {
        let url = params.row['image']
        return (
          <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
            <img src={url} style={{ width: '100px', height: '100px' }} />
          </div>
        )
      },
    },
    { field: 'item-number', headerName: 'Item Number', width: 120 },
    { field: 'name', headerName: 'Product Name', flex: 1 },
    { field: 'category', headerName: 'Category', flex: 1 },
    {
      field: 'bin',
      headerName: 'Available',
      width: 120,
      renderCell: (params) => {
        let bin = params.row['bin']
        return bin && bin != '' && bin != 'N/A' ? <>YES</> : <>NO</>
      },
    },
    {
      field: 'qty',
      headerName: 'Qty',
      width: 320,
      renderCell: (params) => {
        let qtyItemValue = 1
        if (cartItem[params.row['id']] 
          && cartItem[params.row['id']].qty
          && cartItem[params.row['id']].qty != ''
        ) {
          qtyItemValue = cartItem[params.row['id']].qty;
          console.log('qtyItemValue', qtyItemValue);
        }

        let unitItemValue = { value: 'unit', label: 'Unit' }
        if (cartItem[params.row['id']]) {
          unitItemValue = cartItem[params.row['id']].type
        }

        return (
          <div style={{ display: 'block' }}>
            <FormGroup row style={{ marginTop: '20px'}}>
              <div >
                <NumberQty
                  defaultValue={qtyItemValue}
                  onChange={(value) => {
                    params.row['qty'] = value
                    handleUpdateQty(params.row['id'], value)
                  }}
                />
              </div>
              <div style={{width: '182px', marginTop: '10px', marginBottom: '20px'}}>
              <Select
                options={[
                  { value: 'unit', label: 'Unit' },
                  { value: 'case', label: 'Case' },
                ]}
                onChange={(value) => {
                  params.row['type'] = value
                  handleUpdateQtyType(params.row['id'], value)
                }}
                placeholder="Type"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    background: '#fff',
                    borderColor: '#9e9e9e',
                    boxShadow: state.isFocused ? null : null,
                  }),

                  valueContainer: (provided, state) => ({
                    ...provided,
                    padding: '0 6px',
                  }),

                  input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                  }),
                  indicatorSeparator: (state) => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                  }),
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                defaultValue={unitItemValue}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                // isDisabled={cartItem[params.row['id']] ? true : false}
              />
              </div>
            </FormGroup>
          </div>
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (params) => {
        let bin = params.row['bin']
        // if (!bin || bin == '' || bin == 'N/A') {
        //   return <></>
        // }

        return (
          <div style={{ display: 'block' }}>
            {cartItem[params.row['id']] ? (
              <>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    handleRemove(params.row['id'])
                  }}
                  style={{
                    marginTop: '10px',
                    backgroundColor: '#3675af',
                    color: 'white',
                    width: '100%',
                    float: 'left',
                    backgroundColor: 'red',
                  }}
                >
                  Remove
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    handleRecordAddToCart(params.row)
                  }}
                  style={{
                    marginTop: '10px',
                    backgroundColor: '#3675af',
                    color: 'white',
                    width: '100%',
                    float: 'left',
                  }}
                >
                  {params.row['preset'] && <StarsIcon />} Add
                </Button>
              </>
            )}
          </div>
        )
      },
    },
  ]
  const columnsMobile = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 120,
      renderCell: (params) => {
        let url = params.row['image']
        return (
          <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
            <img src={url} style={{ width: '100px', height: '100px' }} />
          </div>
        )
      },
    },
    { field: 'item-number', headerName: 'Item Number', width: 220 },
    { field: 'name', headerName: 'Product Name', width: 320 },
    { field: 'category', headerName: 'Category', width: 320 },
    {
      field: 'bin',
      headerName: 'Available',
      width: 220,
      renderCell: (params) => {
        let bin = params.row['bin']
        return bin && bin != '' && bin != 'N/A' ? <>YES</> : <>NO</>
      },
    },
    {
      field: 'qty',
      headerName: 'Qty',
      width: 320,
      renderCell: (params) => {
        let bin = params.row['bin']
        // if (!bin || bin == '' || bin == 'N/A') {
        //   return <></>
        // }

        let qtyItemValue = 1
        if (cartItem[params.row['id']]) {
          qtyItemValue = cartItem[params.row['id']].qty
        }

        let unitItemValue = { value: 'unit', label: 'Unit' }
        if (cartItem[params.row['id']]) {
          unitItemValue = cartItem[params.row['id']].type
        }

        return (
          <div style={{ display: 'block' }}>
          <FormGroup row style={{ marginTop: '20px'}}>
            <div >
              <NumberQty
                defaultValue={qtyItemValue}
                onChange={(value) => {
                  params.row['qty'] = value
                  handleUpdateQty(params.row['id'], value)
                }}
              />
            </div>
            <div style={{width: '182px', marginTop: '10px', marginBottom: '20px'}}>
            <Select
              options={[
                { value: 'unit', label: 'Unit' },
                { value: 'case', label: 'Case' },
              ]}
              onChange={(value) => {
                params.row['type'] = value
                handleUpdateQtyType(params.row['id'], value)
              }}
              placeholder="Type"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  background: '#fff',
                  borderColor: '#9e9e9e',
                  boxShadow: state.isFocused ? null : null,
                }),

                valueContainer: (provided, state) => ({
                  ...provided,
                  padding: '0 6px',
                }),

                input: (provided, state) => ({
                  ...provided,
                  margin: '0px',
                }),
                indicatorSeparator: (state) => ({
                  display: 'none',
                }),
                indicatorsContainer: (provided, state) => ({
                  ...provided,
                }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              defaultValue={unitItemValue}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              // isDisabled={cartItem[params.row['id']] ? true : false}
            />
            </div>
          </FormGroup>
        </div>
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 320,
      renderCell: (params) => {
        let bin = params.row['bin']
        // if (!bin || bin == '' || bin == 'N/A') {
        //   return <></>
        // }

        return (
          <div style={{ display: 'block' }}>
            {cartItem[params.row['id']] ? (
              <>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    handleRemove(params.row['id'])
                  }}
                  style={{
                    marginTop: '10px',
                    backgroundColor: '#3675af',
                    color: 'white',
                    width: '100%',
                    float: 'left',
                    backgroundColor: 'red',
                  }}
                >
                  Remove
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    handleRecordAddToCart(params.row)
                  }}
                  style={{
                    marginTop: '10px',
                    backgroundColor: '#3675af',
                    color: 'white',
                    width: '100%',
                    float: 'left',
                  }}
                >
                  {params.row['preset'] && <StarsIcon />} Add
                </Button>
              </>
            )}
          </div>
        )
      },
    },
  ]

  const [deliveryAddress, setDeliveryAddress] = useState('')

  useEffect(() => {
    let sum = 0
    Object.keys(cartItem).forEach((key) => {
      sum += parseInt(cartItem[key].qty)
    })
    setCartItemQty(sum)

    const handleBeforeUnload = (event) => {
      // check if cart is not empty
      if (Object.keys(cartItem).length > 0) {
        event.preventDefault()
        event.returnValue =
          'Are you sure you want to reload? Cart will be cleared.'
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [cartItem])

  useEffect(() => {
    if (selected != null) {
      loadProducts(
        {
          'current-page': 1,
          'record-per-page': 100,
        },
        selected,
      )
    }
  }, [selected])

  useEffect(() => {
    if (selected != null) {
      loadProducts(
        {
          'current-page': 1,
          'record-per-page': 100,
        },
        selected,
      )
      searchRef.current.value = ''
      setFilterField('')
    }
  }, [showOnly])

  useEffect(() => {
    if (showMyCart) {
      const summary = []
      Object.keys(cartItem).forEach((key) => {
        summary.push(cartItem[key])
      })
      setRows(summary)
    } else {
      loadPreset()
    }
  }, [showMyCart])

  useEffect(() => {
    if (token) {
      getMyInformation(token).then((res) => {
        let address = res['address-line-1']

        if (res['address-line-2'] && res['address-line-2'] != '') {
          address += ' ' + res['address-line-2']
        }

        setDeliveryAddress(address.trim())
      })
    }
  }, [])

  const updatePageHandler = (pageInfo) => {
    if (filterField === '') {
      return loadProducts({
        'current-page': pageInfo.page + 1,
        'record-per-page': pageInfo.pageSize,
      });
    }

    if (searchRef.current.value === '') {
      return loadProducts({
        'current-page': pageInfo.page + 1,
        'record-per-page': pageInfo.pageSize,
      });
    }

    loadProducts({
      fields: {
        [filterField]: searchRef.current.value,
      },
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    })
  }

  const loadPreset = () => {
    setLoading(true)
    presetList(token, {
      'customer-specific': true,
      'record-per-page': 'all',
      'user-specific': false,
      fields: {},
    }).then((res) => {
      const summary = {}
      const productEntityIds = []
      const collection = res.collection ?? []
      collection.forEach((item) => {
        summary[parseInt(item.data['product-id'])] = item.raw.id
        productEntityIds.push(parseInt(item.data['product-id']))
      })
      setSelected(productEntityIds)
    })
  }

  const loadProducts = (payload, presetSelected = []) => {
    if (presetSelected.length === 0) {
      presetSelected = selected
    }

    getList(token, {
      'user-specific': false,
      entities: showOnly.value === 'my-list' ? presetSelected : [],
      'sort-entities-first': presetSelected,
      'per-page': pageInfoRowPerPage,
      ...payload,
    }).then((res) => {
      const summary = []
      res.collection.forEach((item) => {
        summary.push({
          id: item.raw.id,
          preset: presetSelected.includes(item.raw.id),
          qty: 1,
          type: { value: 'unit', label: 'Unit' },
          ...item.data,
        })
      })
      summary.sort((a, b) => b.preset - a.preset)
      setRows(summary)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      setLoading(false)
    })
  }

  const searchHandler = () => {
    if (filterField === '') {
      return alert('Please select a field to search')
    }

    if (searchRef.current.value === '') {
      return loadProducts({})
    }

    loadProducts({
      fields: {
        [filterField]: searchRef.current.value,
      },
    })
  }

  const handleRecordAddToCart = (data) => {
    const item = { ...cartItem }
    item[data.id] = data

    if (
      item[data.id]['qty'] === '' ||
      item[data.id]['qty'] === null ||
      item[data.id]['qty'] === undefined ||
      item[data.id]['qty'] == 0
    ) {
      alert('Please enter quantity')
    } else {
      setCartItem(item)
    }
  }

  const handleUpdateQty = (id, qty) => {
    const item = { ...cartItem }
    if (item[id]) {
      item[id]['qty'] = qty;
      setCartItem(item)
    }
  }

  const handleUpdateQtyType = (id, type) => {
    const item = { ...cartItem }
    if (item[id]) {
      item[id]['type'] = type
      setCartItem(item)
    }
  }

  const handleRemove = (id) => {
    const item = { ...cartItem }
    delete item[id]
    setCartItem(item)
  }

  const handleSubmit = () => {
    const confim = window.confirm('Are you sure you want to submit this order?')
    if (!confim) {
      return
    }

    if (deliveryAddress == '') {
      return alert('Please enter delivery address')
    }

    setCreateProgress(true)
    createOrder(token, {
      'delivery-address': deliveryAddress,
      items: cartItem,
    }).then((res) => {
      alert('Order has been submitted')
      setCreateProgress(false)
      navigate('/orders')
    })
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {!showMyCart && <h1>Products</h1>}
          </Grid>
          <Grid item xs={8}></Grid>
        </Grid>

        <Grid container spacing={2}>
          {!showMyCart ? (
            <>
              <Grid item xs={12} md={3}>
                <div style={{ marginTop: '20px' }}>
                  <Select
                    options={searchOptions}
                    onChange={(e) => setFilterField(e.value)}
                    placeholder="Filter by"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        background: '#fff',
                        borderColor: '#9e9e9e',
                        height: '55px',
                        boxShadow: state.isFocused ? null : null,
                      }),

                      valueContainer: (provided, state) => ({
                        ...provided,
                        height: '55px',
                        padding: '0 6px',
                      }),

                      input: (provided, state) => ({
                        ...provided,
                        margin: '0px',
                      }),
                      indicatorSeparator: (state) => ({
                        display: 'none',
                      }),
                      indicatorsContainer: (provided, state) => ({
                        ...provided,
                        height: '55px',
                      }),
                    }}
                    value={
                      searchOptions.find((obj) => obj.value === filterField) ??
                      null
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup row style={{ marginTop: '20px' }}>
                  <StyledTextField
                    variant="outlined"
                    placeholder="Search"
                    inputRef={searchRef}
                  />
                  <StyledButton
                    variant="contained"
                    disableElevation
                    onClick={() => searchHandler()}
                  >
                    Search
                  </StyledButton>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={3}>
                <div style={{ marginTop: '20px' }}>
                  <Select
                    options={[
                      { value: 'my-list', label: 'Show My List' },
                      { value: 'all', label: 'Show All Product' },
                    ]}
                    onChange={(e) => setShowOnly(e)}
                    placeholder="Show Products"
                    defaultValue={showOnly}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        background: '#fff',
                        borderColor: '#9e9e9e',
                        height: '55px',
                        boxShadow: state.isFocused ? null : null,
                      }),

                      valueContainer: (provided, state) => ({
                        ...provided,
                        height: '55px',
                        padding: '0 6px',
                      }),

                      input: (provided, state) => ({
                        ...provided,
                        margin: '0px',
                      }),
                      indicatorSeparator: (state) => ({
                        display: 'none',
                      }),
                      indicatorsContainer: (provided, state) => ({
                        ...provided,
                        height: '55px',
                      }),
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    setShowMyCart(!showMyCart)
                  }}
                  style={{
                    marginTop: '20px',
                    backgroundColor:
                      Object.keys(cartItem).length === 0
                        ? '#a3a3a3'
                        : '#3675af',
                    color: 'white',
                    height: '55px',
                    width: '100%',
                  }}
                  disabled={Object.keys(cartItem).length === 0}
                  fullWidth={isMobile}
                >
                  {cartItemQty} <ShoppingCartIcon /> My Cart
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={8}>
                <h1>My Cart</h1>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    setShowMyCart(!showMyCart)
                  }}
                  style={{
                    marginTop: '20px',
                    backgroundColor: '#3675af',
                    color: 'white',
                    height: '55px',
                    width: '100%',
                  }}
                  fullWidth={isMobile}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                {!createProgress ? (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleSubmit()}
                    style={{
                      marginTop: '20px',
                      backgroundColor:
                        Object.keys(cartItem).length === 0
                          ? '#a3a3a3'
                          : '#3675af',
                      color: 'white',
                      height: '55px',
                      width: '100%',
                    }}
                    disabled={Object.keys(cartItem).length === 0}
                  >
                    {cartItemQty} Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() =>
                      alert('Please wait for the order to be submitted')
                    }
                    style={{
                      marginTop: '20px',
                      backgroundColor:
                        Object.keys(cartItem).length === 0
                          ? '#a3a3a3'
                          : '#3675af',
                      color: 'white',
                      height: '55px',
                      width: '100%',
                    }}
                    disabled={Object.keys(cartItem).length === 0}
                  >
                    Loading
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    backgroundColor: '#ffff',
                    padding: '20px',
                    borderRadius: '5px',
                  }}
                >
                  <label>Delivery Address</label>
                  <TextField
                    variant="outlined"
                    placeholder="Delivery Address"
                    fullWidth
                    disabled={createProgress}
                    style={{ marginTop: '10px' }}
                    value={deliveryAddress}
                    onChange={(e) => setDeliveryAddress(e.target.value)}
                  />
                </div>
              </Grid>
            </>
          )}
        </Grid>

        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          {showMyCart ? (
            <DataGrid
              rows={rows}
              columns={isMobile ? columnsMobile : columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
              loading={loading}
              disableColumnFilter
              disableColumnMenu
              rowHeight={125}
            />
          ) : (
            <DataGrid
              rows={rows}
              columns={isMobile ? columnsMobile : columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
                pagination: {
                  paginationModel: {
                    pageSize: pageInfoRowPerPage,
                    page: pageInfoCurrentPage,
                  },
                },
              }}
              pagination
              paginationMode="server"
              rowCount={pageInfoTotalRows}
              loading={loading}
              onPaginationModelChange={(newPage) => updatePageHandler(newPage)}
              disableColumnFilter
              disableColumnMenu
              rowHeight={125}
            />
          )}
        </div>
      </Container>
    </>
  )
}

export default App
