import React, { useState, useContext, useEffect } from 'react'
import ResponsiveAppBar from '../../components/NavbarCustomer'
import { getInfo } from '../../services/invoice'
import { UserContext } from '../../context/UserProvider.jsx'
import { useNavigate } from 'react-router-dom'
import { Grid, Container, Paper, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid'

const App = () => {
  const navigate = useNavigate()
  const [itemData, setItemData] = useState(null)
  const { token } = useContext(UserContext)
  const { id } = useParams()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    getInfo(token, id)
      .then((res) => {
        setItemData({
          ...res.data,
          ...res.data.raw,
        })
      })
      .catch((err) => {
        alert(err.response.data.message)
      })
    }

    const handleDownload = () => {
        window.open(itemData.fileUrl, '_blank');
      }

  if (!itemData) {
    return null
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h1>Invoice Information</h1>
          </Grid>
          <Grid item xs={8}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                navigate(-1)
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
              }}
            >
              Back
            </Button>

            <Button
                  variant="contained"
                  disableElevation
                  onClick={() => handleDownload()}
                  style={{
                    marginTop: '20px',
                    backgroundColor: '#3675af',
                    color: 'white',
                    height: '55px',
                    float: 'right',
                    marginRight: '10px',
                  }}
                >
                  Download Spreadsheet
                </Button>
          </Grid>
        </Grid>
        <div
          style={{
            minHeight: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Review data={itemData} />
          </Paper>
        </div>
      </Container>
    </>
  )
}

const Review = ({ data }) => {
  return (
    <>
      <table className="table-information">
        <tbody>
          <tr>
            <td className="caption">
              <strong>Account Number: </strong>
            </td>
            <td>{data.customerAccount['account-number'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Company Name: </strong>
            </td>
            <td>{data.customerAccount['company-name'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Address Line 1: </strong>
            </td>
            <td>{data.customerAccount['address-line-1'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Address Line 2: </strong>
            </td>
            <td>{data.customerAccount['address-line-2'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Phone Number: </strong>
            </td>
            <td>{data.customerAccount['phone'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Email Address: </strong>
            </td>
            <td>{data.customerAccount['email'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Sub-Total: </strong>
            </td>
            <td>$ {data.subTotal ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Delivery Fee: </strong>
            </td>
            <td>$ {data.deliveryFee ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Tax: </strong>
            </td>
            <td>
              $ {data.tax ?? '0.00'}
            </td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Total: </strong>
            </td>
            <td>$ {data.grandTotal ?? ''}</td>
          </tr>
        </tbody>
      </table>
      <ProductList itemList={data.itemSummary} />
    </>
  )
}

const ProductList = ({ itemList }) => {
  const columns = [
    { field: 'UPC', headerName: 'UPC', flex: 1 },
    { field: 'Item', headerName: 'Item', width: 70 },
    { field: 'Description', headerName: 'Description', flex: 1 },
    { field: 'New Price', headerName: 'Price', flex: 1 },
    { field: 'Qty', headerName: 'Qty', flex: 1 },
    { field: 'C/U', headerName: 'C/U', flex: 1 },
    { field: 'New Total', headerName: 'Total', flex: 1 },
    // { field: 'New Tax', headerName: 'Tax', flex: 1 },
  ]

  return (
    <>
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <DataGrid
          rows={itemList.map((item, index) => {
            return {
              id: index,
              ...item,
            }
          })}
          columns={columns}
        />
      </div>
    </>
  )
}

export default App
