import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { generate, create } from '../../services/invoice'
import { getList } from '../../services/admin'
import { UserContext } from '../../context/UserProvider.jsx'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Button, TextField, withStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { DataGrid } from '@mui/x-data-grid'

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [selectedFile, setSelectedFile] = useState(null)
  const [invoiceData, setInvoiceData] = useState({
    // taxState: 'NY',
    // tax: 0,
    markupPercentage: 0,
    markupCost: 0,
    deliveryFeePercentage: 0,
    deliveryFeeCost: 0,
  })
  const [generatedInfo, setGeneratedInfo] = useState(null)
  const [salesPersonList, setSalesPersonList] = useState([])
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getList(token, {
      'user-specific': false,
    }).then((res) => {
      let tempSalesPersonList = []
      res.collection.forEach((item) => {
        if (item.data.access.control['sales person'] === true) {
          tempSalesPersonList.push({
            value: item.raw.id,
            label: item.data.name,
          })
        }
      })

      setSalesPersonList(tempSalesPersonList)
    })
  }, [])

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleUpload = () => {
    if (!selectedFile) {
      alert('Please upload a file')
      return false
    }
    generate(token, {
      file: selectedFile,
      ...invoiceData,
    }).then((res) => {
      if (res.status && res.status === 404) {
        return false
      }

      setGeneratedInfo(res)
    })
  }

  const handleSubmit = () => {
    const message = window.confirm('Are you sure you want to submit?')

    if (!message) {
      return false
    }

    if (!selectedSalesPerson) {
      alert('Please select a sales person')
      return false
    }
    setLoading(true)
    create(token, {
      'customer-id': generatedInfo.customerAccount.id,
      'invoice-id': generatedInfo.invoiceNumber,
      raw: generatedInfo,
      'invoice-file': generatedInfo.fileUrl,
      status: 'completed',
      'invoice-date': generatedInfo.orderDate,
      'sales-person-id': selectedSalesPerson.value,
      'sales-person-name': selectedSalesPerson.label,
    }).then((res) => {
      setLoading(false)
      if (res.status && res.status === 404) {
        return false
      }

      alert('Invoice has been created successfully')
      navigate('/invoices')
    })
  }

  const handleDownload = () => {
    window.open(generatedInfo.fileUrl, '_blank')
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h1>Create Invoice</h1>
          </Grid>
          <Grid item xs={8}>
            {generatedInfo && (
              <>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => setGeneratedInfo(null)}
                  style={{
                    marginTop: '20px',
                    backgroundColor: '#3675af',
                    color: 'white',
                    height: '55px',
                    float: 'right',
                    marginLeft: '10px',
                  }}
                >
                  Back
                </Button>
                {loading ? (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() =>
                      alert('Please wait for the process to finish')
                    }
                    style={{
                      marginTop: '20px',
                      backgroundColor: '#3675af',
                      color: 'white',
                      height: '55px',
                      float: 'right',
                    }}
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleSubmit()}
                    style={{
                      marginTop: '20px',
                      backgroundColor: '#3675af',
                      color: 'white',
                      height: '55px',
                      float: 'right',
                    }}
                  >
                    Submit
                  </Button>
                )}
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => handleDownload()}
                  style={{
                    marginTop: '20px',
                    backgroundColor: '#3675af',
                    color: 'white',
                    height: '55px',
                    float: 'right',
                    marginRight: '10px',
                  }}
                >
                  Download Spreadsheet
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <div
          style={{
            minHeight: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
            padding: '20px',
          }}
        >
          {!generatedInfo ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <label htmlFor="file-input">
                    <div className="file-label">
                      {selectedFile ? selectedFile.name : 'Upload Excel File'}
                    </div>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept=".xls, .xlsx"
                    onChange={handleFileChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Markup - Percentage (%)"
                    variant="outlined"
                    placeholder="Qty"
                    type="number"
                    size="small"
                    min={0}
                    style={{
                      marginTop: '5px',
                      width: '45%',
                      marginRight: '5%',
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    defaultValue={invoiceData.markupPercentage}
                    onChange={(e) => {
                      setInvoiceData({
                        ...invoiceData,
                        markupPercentage: e.target.value,
                      })
                    }}
                  />
                  <TextField
                    label="Markup - Cost (USD)"
                    variant="outlined"
                    placeholder="Qty"
                    type="number"
                    size="small"
                    min={0}
                    style={{ marginTop: '5px', width: '45%' }}
                    InputProps={{ inputProps: { min: 0 } }}
                    defaultValue={invoiceData.markupCost}
                    onChange={(e) => {
                      setInvoiceData({
                        ...invoiceData,
                        markupCost: e.target.value,
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Delivery Fee - %"
                    variant="outlined"
                    placeholder="Qty"
                    type="number"
                    size="small"
                    min={0}
                    style={{
                      marginTop: '5px',
                      width: '45%',
                      marginRight: '5%',
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    defaultValue={invoiceData.deliveryFeePercentage}
                    onChange={(e) => {
                      setInvoiceData({
                        ...invoiceData,
                        deliveryFeePercentage: e.target.value,
                      })
                    }}
                  />
                  <TextField
                    label="Delivery Fee - Cost (USD)"
                    variant="outlined"
                    placeholder="Qty"
                    type="number"
                    size="small"
                    min={0}
                    InputProps={{ inputProps: { min: 0 } }}
                    style={{ marginTop: '5px', width: '45%' }}
                    defaultValue={invoiceData.deliveryFeeCost}
                    onChange={(e) => {
                      setInvoiceData({
                        ...invoiceData,
                        deliveryFeeCost: e.target.value,
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                {/* <Grid item xs={6}>
                  <div
                    style={{ width: '45%', float: 'left', marginRight: '5%' }}
                  >
                    <label style={{ fontSize: '10px' }}>Tax - State</label>
                    <Select
                      options={[
                        { value: 'NY', label: 'New York' },
                        { value: 'NJ', label: 'New Jersey' },
                      ]}
                      onChange={(data) => {
                        setInvoiceData({
                          ...invoiceData,
                          taxState: data.value,
                        })
                      }}
                      placeholder="Tax - State"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          background: '#fff',
                          borderColor: '#9e9e9e',
                          boxShadow: state.isFocused ? null : null,
                        }),

                        valueContainer: (provided, state) => ({
                          ...provided,
                          padding: '0 6px',
                        }),

                        input: (provided, state) => ({
                          ...provided,
                          margin: '0px',
                        }),
                        indicatorSeparator: (state) => ({
                          display: 'none',
                        }),
                        indicatorsContainer: (provided, state) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      defaultValue={{ value: 'NY', label: 'New York' }}
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                    />
                  </div>
                  <TextField
                    label="Tax - Percentage (%)"
                    variant="outlined"
                    placeholder="Qty"
                    type="number"
                    size="small"
                    min={0}
                    style={{ marginTop: '15px', width: '45%' }}
                    InputProps={{ inputProps: { min: 0 } }}
                    defaultValue={invoiceData.tax}
                    onChange={(e) => {
                      setInvoiceData({ ...invoiceData, tax: e.target.value })
                    }}
                  />
                </Grid> */}
              </Grid>

              <button className="upload-button" onClick={() => handleUpload()}>
                Submit
              </button>
            </>
          ) : (
            <Review
              data={generatedInfo}
              salesPersonList={salesPersonList}
              setSelectedSalesPerson={setSelectedSalesPerson}
            />
          )}
        </div>
      </Container>
    </>
  )
}

const Review = ({ data, salesPersonList, setSelectedSalesPerson }) => {
  useEffect(() => {
    if (data) {
      setSelectedSalesPerson({
        value: data.customerAccount['sales-person'].value,
        label: data.customerAccount['sales-person'].label,
      })
    }
  }, [data])

  return (
    <>
      <table className="table-information">
        <tbody>
          <tr>
            <td className="caption">
              <strong>Sales Person: </strong>
            </td>
            <td>
              <Select
                defaultValue={data.customerAccount['sales-person']}
                options={salesPersonList}
                onChange={(e) => setSelectedSalesPerson(e)}
                placeholder="Sales Person"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    background: '#fff',
                    borderColor: '#9e9e9e',
                    height: '55px',
                    boxShadow: state.isFocused ? null : null,
                  }),

                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                    padding: '0 6px',
                  }),

                  input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                  }),
                  indicatorSeparator: (state) => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                  }),
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Account Number: </strong>
            </td>
            <td>{data.customerAccount['account-number'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Company Name: </strong>
            </td>
            <td>{data.customerAccount['company-name'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Address Line 1: </strong>
            </td>
            <td>{data.customerAccount['address-line-1'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Address Line 2: </strong>
            </td>
            <td>{data.customerAccount['address-line-2'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Phone Number: </strong>
            </td>
            <td>{data.customerAccount['phone'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Email Address: </strong>
            </td>
            <td>{data.customerAccount['email'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Sub-Total: </strong>
            </td>
            <td>$ {data.subTotal ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Delivery Fee: </strong>
            </td>
            <td>$ {data.deliveryFee ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Tax: </strong>
            </td>
            <td>$ {data.tax ?? '0.00'}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Total: </strong>
            </td>
            <td>$ {data.grandTotal ?? ''}</td>
          </tr>
        </tbody>
      </table>
      <ProductList itemList={data.itemSummary} />
    </>
  )
}

const ProductList = ({ itemList }) => {
  const columns = [
    { field: 'UPC', headerName: 'UPC', flex: 1 },
    { field: 'Item', headerName: 'Item', width: 70 },
    { field: 'Description', headerName: 'Description', flex: 1 },
    { field: 'Price', headerName: 'Unconverted Price', flex: 1 },
    { field: 'New Price', headerName: 'Converted Price', flex: 1 },
    { field: 'Qty', headerName: 'Qty', flex: 1 },
    { field: 'C/U', headerName: 'C/U', flex: 1 },
    { field: 'New Total', headerName: 'Total', flex: 1 },
    // { field: 'New Tax', headerName: 'Tax', flex: 1 },
  ]

  return (
    <>
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <DataGrid
          rows={itemList.map((item, index) => {
            return {
              id: index,
              ...item,
            }
          })}
          columns={columns}
        />
      </div>
    </>
  )
}

export default App
