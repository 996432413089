import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getList } from '../../services/product'
import { 
  getList as presetList,
  remove as removePreset,
  removeAll as removeAllPreset
 } from '../../services/preset'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import { FormGroup, TextField, withStyles } from '@material-ui/core'
import { Button, Container, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import Tab from './tab.jsx'
import { isMobile } from 'react-device-detect'

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '74%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)

const App = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(100)
  const [loading, setLoading] = useState(true)
  const [filterField, setFilterField] = useState('')
  const searchRef = useRef(null)
  const [selected, setSelected] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 120,
      renderCell: (params) => {
        let url = params.row['image']
        return (
          <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
            <img src={url} style={{ width: '100px', height: '100px' }} />
          </div>
        )
      },
    },
    { field: 'item-number', headerName: 'Item Number', width: 120 },
    { field: 'name', headerName: 'Product Name', flex: 1 },
    { field: 'category', headerName: 'Category', width: 220 },
    { field: 'bin', headerName: 'Bin Number', width: 120 },
    { field: 'upc', headerName: 'UPC', width: 120 },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ display: 'block' }}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                handleRecordSelect(params.row['id'])
              }}
              style={{
                marginTop: '10px',
                backgroundColor: '#3675af',
                color: 'white',
                width: '100%',
                float: 'left',
              }}
            >
              View
            </Button>

            {selected[params.row['id']] && (
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  handleRemove(params.row['id'])
                }}
                style={{
                  marginTop: '10px',
                  backgroundColor: '#3675af',
                  color: 'white',
                  width: '100%',
                  float: 'left',
                  backgroundColor: 'red',
                }}
              >
                Remove
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  const columnsMobile = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 220,
      renderCell: (params) => {
        let url = params.row['image']
        return (
          <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
            <img src={url} style={{ width: '100px', height: '100px' }} />
          </div>
        )
      },
    },
    { field: 'item-number', headerName: 'Item Number', width: 220 },
    { field: 'name', headerName: 'Product Name', width: 320 },
    { field: 'category', headerName: 'Category', width: 220 },
    { field: 'bin', headerName: 'Bin Number', width: 120 },
    { field: 'upc', headerName: 'UPC', width: 120 },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ display: 'block' }}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                handleRecordSelect(params.row['id'])
              }}
              style={{
                marginTop: '10px',
                backgroundColor: '#3675af',
                color: 'white',
                width: '100%',
                float: 'left',
              }}
            >
              View
            </Button>

            {selected[params.row['id']] && (
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  handleRemove(params.row['id'])
                }}
                style={{
                  marginTop: '10px',
                  backgroundColor: '#3675af',
                  color: 'white',
                  width: '100%',
                  float: 'left',
                  backgroundColor: 'red',
                }}
              >
                Remove
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 100,
    })
  }, []);

  const handleRemove = (id) => {
    setLoading(true);
    removePreset(token, selected[id]).then((res) => {
      loadData({
        'current-page': 1,
        'record-per-page': 100,
      })
    })
  }

  const updatePageHandler = (pageInfo) => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    })
  }

  const loadData = (payload) => {
    setLoading(true)
    presetList(token, {
      "record-per-page": "all",
      "fields": {
        "customer-id": id
      },
      'user-specific': false,
    }).then((res) => {
      const summary = {}
      const productEntityIds = [];
      const collection = res.collection ?? [];
      collection.forEach((item) => {
        summary[parseInt(item.data['product-id'])] = item.raw.id;
        productEntityIds.push(parseInt(item.data['product-id']));
      })
      setSelected(summary)
      if (productEntityIds.length > 0) {
        loadProducts({
          'current-page': 1,
          'record-per-page': 100,
          "entities": productEntityIds,
          ...payload,
          'user-specific': false,
        })
      } else {
        setLoading(false)
      }
    })
  }

  const loadProducts = (payload) => {
    getList(token, {
      ...payload,
      'user-specific': false,
    }).then((res) => { 
      const summary = []
      res.collection.forEach((item) => {
        summary.push({
          id: item.raw.id,
          ...item.data,
        })
      })
      setRows(summary)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      setLoading(false)
    });
  }

  const searchHandler = () => {
    if (filterField === '') {
      return alert('Please select a field to search')
    }

    if (searchRef.current.value === '') {
      window.location.reload();
    }

    loadData({"fields": {
      [filterField]: searchRef.current.value
    }});
  }

  const handleRecordSelect = (productId) => {
    navigate(`/products/${productId}/information`)
  }

  const handleClearList = () => {
    const confirm = window.confirm('Are you sure you want to clear the list? This will remove all products from the list and cannot be undone.');
    if (confirm) {
      setLoading(true);
      removeAllPreset(token, id).then((res) => {
        setSelectedProducts([]);
        setSelected([]);
        setRows([]);
        loadData({
          'current-page': 1,
          'record-per-page': 100,
        });
      });
    };
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h1>Customer Products</h1>
          </Grid>
          <Grid item xs={12} md={8}>
            <Tab />
          </Grid>
        </Grid>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <div style={{ marginTop: '20px' }}>
              <Select
                options={[
                  { value: 'item-number', label: 'Item Number' },
                  { value: 'name', label: 'Product Name' },
                  { value: 'category', label: 'Category' },
                  { value: 'bin', label: 'Bin Number' },
                  { value: 'upc', label: 'UPC' },
                ]}
                onChange={(e) => setFilterField(e.value)}
                placeholder="Filter by"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    background: '#fff',
                    borderColor: '#9e9e9e',
                    height: '55px',
                    boxShadow: state.isFocused ? null : null,
                  }),

                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                    padding: '0 6px',
                  }),

                  input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                  }),
                  indicatorSeparator: (state) => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormGroup row style={{ marginTop: '20px' }}>
              <StyledTextField
                variant="outlined"
                placeholder="Search"
                inputRef={searchRef}
              />
              <StyledButton
                variant="contained"
                disableElevation
                onClick={() => searchHandler()}
              >
                Search
              </StyledButton>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                navigate(`/customers/${id}/products/add`)
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                width: '100%',
              }}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => handleClearList()}
              style={{
                marginTop: '20px',
                backgroundColor: (rows.length > 0 ? 'red' : 'grey'),
                color: 'white',
                height: '55px',
                width: '100%',
              }}
              disabled={rows.length === 0}
            >
              Clear
            </Button>
          </Grid>
        </Grid>

        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rows={rows}
            columns={isMobile? columnsMobile : columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageInfoRowPerPage,
                  page: pageInfoCurrentPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            rowCount={pageInfoTotalRows}
            loading={loading}
            onPaginationModelChange={(newPage) => updatePageHandler(newPage)}
            disableColumnFilter
            disableColumnMenu
            rowHeight={125}
          />
        </div>
      </Container>
    </>
  )
}

export default App
