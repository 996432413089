import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getInformation } from '../../services/admin'
import { UserContext } from '../../context/UserProvider.jsx'
import { useNavigate } from 'react-router-dom'
import {
  TextField,
  Grid,
  Container,
  Paper,
  Button,
  InputAdornment,
  IconButton
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

const App = () => {

  const [userData, setUserData] = useState(null);
  const { token } = useContext(UserContext);
  const { id } = useParams();

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    getInformation(token, id).then((res) => {
      setUserData(res.data)
    }).catch((err) => {
      alert(err.response.data.message);
    });
  }

  if (!userData) {
    return null
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h1>User Information</h1>
          </Grid>
          <Grid item xs={8}>
          
          </Grid>
        </Grid>
        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <Paper elevation={0} sx={{ padding: 3 }}>
           <InformationView
                userData={userData}
              />
          </Paper>
        </div>
      </Container>
    </>
  )
}

const ListAccess = ({list}) => {
  const renderList = [];
  for (const key in list) {
    renderList.push(<li key={renderList}>{key.toLocaleUpperCase()}: {list[key] ? '✅' : '❌'} </li>);
  }

  return <>
    <ul>
      {renderList}
    </ul>
  </>
}

const InformationView = ({ userData }) => {
  const { id } = useParams();
  const navigate = useNavigate()

  return (
    <>
      <table className="table-information">
        <tbody>
        <tr>
            <td className="caption">
              <strong>Name: </strong>
            </td>
            <td>{userData['name'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Email Address: </strong>
            </td>
            <td>{userData['email'] ?? ''}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Access: </strong>
            </td>
            <td>
              <ListAccess list={userData['access'].control} />
            </td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Notification: </strong>
            </td>
            <td>
              <ListAccess list={userData['access'].notifications} />
            </td>
          </tr>
        </tbody>
      </table>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: '20px', float: 'right' }}
        onClick={() => {
          navigate(`/users/${id}/update`)
        }}
      >
        Update
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: '20px', float: 'right', marginRight: '10px' }}
        onClick={() => {
          navigate(-1)
        }}
      >
        Back
      </Button>
    </>
  )
}

export default App
