import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/NavbarCustomer'
import { getList } from '../../services/product'
import { getItemList as geteOrderItems } from '../../services/order'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import { FormGroup, TextField, withStyles } from '@material-ui/core'
import { Button, Container, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import StarsIcon from '@mui/icons-material/Stars'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { isMobile } from 'react-device-detect';
import { common } from '@mui/material/colors'

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '75%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)

const App = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25)
  const [loading, setLoading] = useState(true)
  const [filterField, setFilterField] = useState('')
  const searchRef = useRef(null)
  const [selected, setSelected] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 120,
      renderCell: (params) => {
        let url = params.row['image']
        return (
          <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
            <img src={url} style={{ width: '100px', height: '100px' }} />
          </div>
        )
      },
    },
    { field: 'item-number', headerName: 'Item Number', width: 120 },
    { field: 'name', headerName: 'Product Name', flex: 1 },
    { field: 'category', headerName: 'Category', width: 220 },
    { field: 'qty', headerName: 'Qty', width: 120 },
    { field: 'type', headerName: 'Type', width: 120 },
  ]

  const columnsMobile = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 220,
      renderCell: (params) => {
        let url = params.row['image']
        return (
          <div style={{ width: '100%', textAlign: 'center', height: '100px' }}>
            <img src={url} style={{ width: '100px', height: '100px' }} />
          </div>
        )
      },
    },
    { field: 'item-number', headerName: 'Item Number', width: 220 },
    { field: 'name', headerName: 'Product Name',   width: 220, },
    { field: 'category', headerName: 'Category', width: 220 },
    { field: 'qty', headerName: 'Qty', width: 120 },
    { field: 'type', headerName: 'Type', width: 120 },
  ]

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 25,
    })
  }, []);

  const updatePageHandler = (pageInfo) => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    })
  }

  const loadData = (payload) => {
    setLoading(true)
    geteOrderItems(token, {
      'current-page': 1,
      'record-per-page': 'all',
      "user-specific": false,
      "fields": {
        "order-id": id
      }
    }).then((res) => {
      const summary = {}
      const productEntityIds = [];
      const collection = res.collection ?? [];
      collection.forEach((item) => {
        summary[parseInt(item.data['product-id'])] = item.data;
        productEntityIds.push(parseInt(item.data['product-id']));
      })
      setSelected(summary)
      if (productEntityIds.length > 0) {
        loadProducts({
          'current-page': 1,
          'record-per-page': productEntityIds.length,
          "entities": productEntityIds,
          "user-specific": false,
          ...payload
        }, summary)
      } else {
        setLoading(false)
      }
    })
  }

  const loadProducts = (payload, presetSummary = []) => {
    getList(token, payload).then((res) => { 
      presetSummary = presetSummary ?? selected;
      const summary = []
      res.collection.forEach((item) => {
        summary.push({
          id: item.raw.id,
          qty: presetSummary[item.raw.id] ? presetSummary[item.raw.id]['qty'] : 0,
          type: presetSummary[item.raw.id] ? presetSummary[item.raw.id]['type'].toUpperCase() : '',
          ...item.data,
        })
      })
      setRows(summary)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      setLoading(false)
    });
  }

  const searchHandler = () => {
    if (filterField === '') {
      return alert('Please select a field to search')
    }

    loadData({"fields": {
      [filterField]: searchRef.current.value
    }});
  }

  const handleRecordSelect = (productId) => {
    navigate(`/products/${productId}/information`)
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h1>Order Information</h1>
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ marginTop: '20px' }}>
              <Select
                options={[
                  { value: 'item-number', label: 'Item Number' },
                  { value: 'name', label: 'Product Name' },
                  { value: 'category', label: 'Category' },
                ]}
                onChange={(e) => setFilterField(e.value)}
                placeholder="Filter by"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    background: '#fff',
                    borderColor: '#9e9e9e',
                    height: '55px',
                    boxShadow: state.isFocused ? null : null,
                  }),

                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                    padding: '0 6px',
                  }),

                  input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                  }),
                  indicatorSeparator: (state) => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup row style={{ marginTop: '20px' }}>
              <StyledTextField
                variant="outlined"
                placeholder="Search"
                inputRef={searchRef}
              />
              <StyledButton
                variant="contained"
                disableElevation
                onClick={() => searchHandler()}
              >
                Search
              </StyledButton>
            </FormGroup>
          </Grid>
        </Grid>

        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rows={rows}
            columns={isMobile ? columnsMobile : columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageInfoRowPerPage,
                  page: pageInfoCurrentPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            rowCount={pageInfoTotalRows}
            loading={loading}
            onPaginationModelChange={(newPage) => updatePageHandler(newPage)}
            disableColumnFilter
            disableColumnMenu
            rowHeight={125}
          />
        </div>
      </Container>
    </>
  )
}

export default App
