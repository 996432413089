import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { update, getInformation } from '../../services/admin'
import { UserContext } from '../../context/UserProvider.jsx'
import { useNavigate } from 'react-router-dom'
import {
  TextField,
  Button,
  Grid,
  Container,
  Paper,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

const Form = ({ onSubmit, defaultData }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [formData, setFormData] = useState(defaultData)

  const handleGeneratePassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8)
    setFormData((prevFormData) => ({
      ...prevFormData,
      password: randomPassword,
    }))
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(formData)
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const accessControl = []
  {
    Object.entries(formData.access.control).forEach(([key, item]) => {
      accessControl.push(
        <FormControlLabel
          key={key}
          control={
            <Checkbox
              checked={item}
              onChange={() => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  access: {
                    ...prevFormData.access,
                    control: {
                      ...prevFormData.access.control,
                      [key]: !item,
                    },
                  },
                }))
              }}
              name={key}
            />
          }
          label={key.toLocaleUpperCase()}
        />,
      )
    })
  }

  const notifications = []
  {
    Object.entries(formData.access.notifications).forEach(([key, item]) => {
      notifications.push(
        <FormControlLabel
          key={key}
          control={
            <Checkbox
              checked={item}
              onChange={() => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  access: {
                    ...prevFormData.access,
                    notifications: {
                      ...prevFormData.access.notifications,
                      [key]: !item,
                    },
                  },
                }))
              }}
              name={key}
            />
          }
          label={key.toLocaleUpperCase()}
        />,
      )
    })
  }

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <label>
              <strong>Access Control</strong>
            </label>
            <p>{accessControl}</p>
            <label>
              <strong>Notification</strong>
            </label>
            <p>{notifications}</p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleGeneratePassword}>
              Generate Password
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

const App = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { token } = useContext(UserContext)
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    getInformation(token, id)
      .then((res) => {
        let defaultData = res.data;
        delete defaultData.password;

        setUserData(res.data)
      })
      .catch((err) => {
        alert(err.response.data.message)
      })
  }

  const handleSubmit = (payload) => {
    if (payload.password === '') {
      delete payload.password;
    }

    update(token, payload, id).then((res) => {
      if (res.status && res.status === 405) {
        return alert(res.data.message)
      }

      alert('User data updated successfully')
      navigate(`/users/${id}/information`)
    })
  }

  if (!userData) {
    return null
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h1>Update User</h1>
          </Grid>

          <Grid item xs={8}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                navigate(-1)
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
              }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <Form onSubmit={handleSubmit} defaultData={userData} />
        </div>
      </Container>
    </>
  )
}

export default App
