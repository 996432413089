import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import LoadingScreen from '../LoadingScreen'

export default function StickyHeadTable(props) {
  const {
    rows,
    columns,
    onRowClick,
    loading,
    updatePageHandler,
    rowCount,
    pageSize,
    pageCurrent,
  } = props
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(0)
  const [rowsData, setRowsData] = React.useState(null)
  const handleChangePage = (event, newPage) => {
    updatePageHandler({
      page: newPage,
      pageSize: +rowsPerPage,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    updatePageHandler({
      page: 0,
      pageSize: +event.target.value,
    })
  }

  const handleRowClick = (rowData) => {
    onRowClick(rowData)
  }

  React.useEffect(() => {
    setRowsPerPage(pageSize)
  }, [pageSize])

  React.useEffect(() => {
    let currentPageSet = pageCurrent - 1
    if (currentPageSet < 0) {
      currentPageSet = 0
    }
    setPage(currentPageSet)
  }, [pageCurrent])

  React.useEffect(() => {
    setRowsData(rows)
  }, [rows])

  React.useEffect(() => {
    if (loading) {
        setRowsData(null);
    }
  }, [loading])

  const RenderRows = ({
    tempRowsData,
    tempRowsPerPage,
  }) => {
    let rowDom = tempRowsData
      .slice(0 * tempRowsPerPage, 0 * tempRowsPerPage + tempRowsPerPage)
      .map((row, rowIndex) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={`table-row-${rowIndex}`}
            onClick={() => handleRowClick(row)}
          >
            {columns.map((column) => {
              const value = row[column.field]
              if (column.hidden) {
                return null
              }

              if (column.renderCell) {
                return (
                  <TableCell
                    key={`table-data-${column.field}-${rowIndex}`}
                    align={column.align}
                  >
                    {column.renderCell({row: row})}
                  </TableCell>
                )
              }

              return (
                <TableCell
                  key={`table-data-${column.field}-${rowIndex}`}
                  align={column.align}
                >
                  {value}
                </TableCell>
              )
            })}
          </TableRow>
        )
      })
      console.log('rowDom:', rowDom)
    return rowDom
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {loading || !rowsData ? (
        <LoadingScreen />
      ) : (
        <>
          <TableContainer sx={{ maxHeight: '65vh' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
              <TableRow>
                    {columns.map((column, index) => (
                        column.hidden ? null : (
                            <TableCell
                                key={`table-header-${column.field}-${index}`}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                {column.headerName}
                            </TableCell>
                        )
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderRows
                    tempRowsData={rowsData}
                    tempRowsPerPage={rowsPerPage}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 500, 1000]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  )
}
