import axios from 'axios';

export const create = (token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.post(`${process.env.REACT_APP_API}/order`, payload, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}

export const getInfo = (token, id) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.get(`${process.env.REACT_APP_API}/order-management/${id}`, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}

export const getList = (token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.post(`${process.env.REACT_APP_API}/order-management/list`, payload, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}

export const getItemList = (token, payload) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return axios.post(`${process.env.REACT_APP_API}/order-item-management/list`, payload, { headers })
    .then(function(response) {
        return response.data;
    })
    .catch(error => {
        if (error.response.status === 401)  {
            localStorage.removeItem('token');
            return window.location.href = '/';
        } else if (error.response.status === 404) {
            return window.location.href = '/404';
        }

        return error.response;
    });
}