import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/NavbarCustomer'
import { getList } from '../../services/invoice'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import { FormGroup, TextField, withStyles } from '@material-ui/core'
import { Button, Container, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import StarsIcon from '@mui/icons-material/Stars'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { isMobile } from 'react-device-detect';

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '74%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)

const App = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25)
  const [loading, setLoading] = useState(true)
  const searchRef = useRef(null)
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'invoiceNumber', headerName: 'Invoice Number', flex: 1 },
    { field: 'grandTotal', headerName: 'Total', flex: 1 },
    { field: 'orderDate', headerName: 'Transaction Date', flex: 1 },
    { field: 'updated_last', headerName: 'Invoice Date', flex: 1 },
  ]

  const columnsMobile = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'invoiceNumber', headerName: 'Invoice Number', width: 270 },
    { field: 'grandTotal', headerName: 'Total', width: 270 },
    { field: 'orderDate', headerName: 'Transaction Date', width: 270 },
    { field: 'updated_last', headerName: 'Invoice Date', width: 270 },
  ]

  useEffect(() => {
    load()
  }, [])

  const updatePageHandler = (pageInfo) => {
    load({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    })
  }
  const load = (payload) => {
    setLoading(true)
    getList(token, {
      'user-specific': false,
      'customer-specific': true,
      ...payload,
    }).then((res) => {
      const summary = []

      res.collection.forEach((item) => {
        let updatedLast = new Date(item.raw.updated_last)
        updatedLast = updatedLast.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })
       
        let orderDate = new Date(item.data.raw.orderDate)
        orderDate = orderDate.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })

        summary.push({
            id: item.raw.id,
            ...item.data,
            ...item.data.raw,
            orderDate: orderDate,
            updated_last: updatedLast,
        })
      })
      console.log(summary)
      setRows(summary)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      setLoading(false)
    })
  }

  const searchHandler = () => {
    load({
      fields: {
        'invoice-id': searchRef.current.value,
      },
    })
  }

  const handleRecordSelect = (row) => {
    navigate(`/invoices/${row.id}/information`);
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h1>Invoices</h1>
          </Grid>
          <Grid item xs={12} md={8} style={{marginTop: '20px'}}>
            <FormGroup row>
              <StyledTextField
                variant="outlined"
                placeholder="Search Invoice Number"
                inputRef={searchRef}
              />
              <StyledButton
                variant="contained"
                disableElevation
                onClick={() => searchHandler()}
              >
                Search
              </StyledButton>
            </FormGroup>
          </Grid>
        </Grid>


        <div
          style={{
            height: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rows={rows}
            columns={isMobile ? columnsMobile : columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageInfoRowPerPage,
                  page: pageInfoCurrentPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            rowCount={pageInfoTotalRows}
            loading={loading}
            onPaginationModelChange={(newPage) => updatePageHandler(newPage)}
            disableColumnFilter
            disableColumnMenu
            onRowClick={handleRecordSelect} 
          />
        </div>
      </Container>
    </>
  )
}

export default App
