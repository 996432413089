import React, { useState, useContext, useEffect, useRef } from 'react'
import ResponsiveAppBar from '../../components/Navbar'
import { getList } from '../../services/invoice'
import { getList as getCustomerList } from '../../services/customer'
import { UserContext } from '../../context/UserProvider.jsx'
import { DataGrid } from '@mui/x-data-grid'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Button, FormGroup, TextField, withStyles } from '@material-ui/core'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ReplayIcon from '@mui/icons-material/Replay'
import { isMobile } from 'react-device-detect'
import Table from './../../components/Table'

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    backgroundColor: '#fff',
    width: '74%',
  },
})(TextField)

const StyledButton = withStyles({
  root: {
    backgroundColor: '#3675af',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'white',
  },
})(Button)


let firstDayOfTheMonth = new Date()
firstDayOfTheMonth.setDate(1);
firstDayOfTheMonth = firstDayOfTheMonth.toLocaleString('en-US', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});

let lastDayOfTheMonth = new Date()
lastDayOfTheMonth.setMonth(lastDayOfTheMonth.getMonth())
lastDayOfTheMonth = lastDayOfTheMonth.toLocaleString('en-US', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});

const App = () => {
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [excelRows, setExcelRows] = useState([])
  const [excelTotalData, setExcelTotalData] = useState(0)
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0)
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0)
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(100)
  const [loading, setLoading] = useState(true)
  const searchRef = useRef(null)
  const [searchType, setSearchType] = useState('text')
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      filterable: false,
      hidden: true,
    },
    { field: 'orderDate', headerName: 'Date', width: 110, filterable: false },
    {
      field: 'invoiceNumber',
      headerName: 'Invoice #',
      width: 110,
      filterable: false,
    },
    {
      field: 'company-name',
      headerName: 'Customer',
      flex: 1,
      renderCell: (params) => {
        let customerId = params.row['customer-id']
        return (
          <>
            {customers[customerId]['account-number']} -{' '}
            {customers[customerId]['company-name']}
          </>
        )
      },
    },
    {
      field: 'grandTotal',
      headerName: 'Converted',
      width: 150,
      renderCell: (params) => {
        return <>$ {params.row['grandTotal']}</>
      },
    },
    {
      field: 'totalUnconverted',
      headerName: 'Unconverted',
      width: 150,
      renderCell: (params) => {
        return <>$ {params.row['totalUnconverted']}</>
      },
    },
    {
      field: 'deliveryFee',
      headerName: 'Del Fee',
      width: 150,
      renderCell: (params) => {
        return <>$ {params.row['deliveryFee']}</>
      },
    },
    {
      field: 'computationNet',
      headerName: 'Net',
      width: 150,
      renderCell: (params) => {
        return <>$ {params.row['computationNet']}</>
      },
    },
    { field: 'computationPct', headerName: 'PCT', width: 110 },
    { field: 'sales-person-name', headerName: 'Sales Person', flex: 1 },
  ]
  const [customers, setCustomers] = useState(null)
  const [summaryData, setSummaryData] = useState(null)
  const [summaryDataWithDelivery, setSummaryDataWithDelivery] = useState(null)
  const [summaryDataNoDelivery, setSummaryDataNoDelivery] = useState(null)

  let today = new Date();
  let firstDayOfTheCurrentWeek = new Date(today.setDate(today.getDate() - today.getDay()));
  let lastDayOfTheCurrentWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

  const [dateRange, setDateRange] = useState({
    startDate: firstDayOfTheCurrentWeek,
    endDate: lastDayOfTheCurrentWeek,
    key: 'selection',
  });

  const [showDateRange, setShowDateRange] = useState(false)
  const [filterField, setFilterField] = useState('')

  useEffect(() => {
    loadCustomer()
  }, [])

  useEffect(() => {
    if (!customers) {
      return
    }

    loadData({
      fields: {
        'invoice-date': {
          start: firstDayOfTheMonth,
          end: lastDayOfTheMonth,
        }
      },
      operator: '=',
      'current-page': 1,
      'record-per-page': 1000,
      'user-specific': false,
    })
  }, [customers])

  useEffect(() => {
    if (!rows) {
      return
    }

    getSummary()
  }, [rows])

  const updatePageHandler = (pageInfo) => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
      'user-specific': false,
    })
  }

  const loadData = (payload) => {
    setLoading(true)
    getList(token, payload).then((res) => {
      const summary = []
      const summaryExcel = []
      res.collection.forEach((item) => {
        let updatedLast = new Date(item.raw.updated_last)
        updatedLast = updatedLast.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })

        let orderDate = new Date(item.data.raw.orderDate)
        orderDate = orderDate.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })

        summary.push({
          id: item.raw.id,
          ...item.data,
          ...item.data.raw,
          orderDate: orderDate,
          updated_last: updatedLast,
        })

        item.data.raw.grandTotal = item.data.raw.grandTotal.replace(/,/g, '')
        item.data.raw.totalUnconverted = item.data.raw.totalUnconverted.replace(
          /,/g,
          '',
        )
        item.data.raw.deliveryFee = item.data.raw.deliveryFee.replace(/,/g, '')
        item.data.raw.computationNet = item.data.raw.computationNet.replace(
          /,/g,
          '',
        )

        summaryExcel.push({
          Date: orderDate,
          'Invoice #': item.data.raw.invoiceNumber,
          Customer: customers[item.data['customer-id']]
            ? customers[item.data['customer-id']]['company-name']
            : '',
          Converted: item.data.raw.grandTotal,
          Unconverted: item.data.raw.totalUnconverted,
          'Del Fee': item.data.raw.deliveryFee,
          Net: item.data.raw.computationNet,
          PCT: item.data.raw.computationPct,
          'Sales Person': item.data['sales-person-name'],
        })
      })
      console.log(summary)
      setRows(summary)
      setExcelRows(summaryExcel)
      setPageInfoCurrentPage(res['current-page'])
      setPageInfoRowPerPage(res['record-per-page'])
      setPageInfoTotalRows(res['data-total'])
      let totalRows = res['data-to'] - res['data-from'] + 1
      setExcelTotalData(totalRows)
      setLoading(false)
    })
  }

  const loadCustomer = () => {
    setLoading(true)
    getCustomerList(token, {
      'current-page': 1,
      'record-per-page': 'all',
      'user-specific': false,
    }).then((res) => {
      const summary = {}
      res.collection.forEach((item) => {
        summary[item.raw.id] = item.data
      })

      // sort by company name
      const ordered = {}
      Object.keys(summary)
        .sort()
        .forEach(function (key) {
          ordered[key] = summary[key]
        })

      setCustomers(ordered)
    })
  }

  const searchHandler = (keywords = null) => {
    if (keywords === null) {
      keywords = searchRef.current.value
    }

    const dateStartFormat = dateRange.startDate?.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }) || dateRange.startDate;

    const dateEndFormat = dateRange.endDate?.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }) || dateRange.endDate;

    let fieldsToSearch = {
      'invoice-date': {
        start: dateStartFormat,
        end: dateEndFormat,
      },
    }
    let operator = 'like'
    if (keywords !== '' && filterField !== '') {
      if (filterField === 'customer-id') {
        operator = '='
        keywords = parseInt(keywords)
      }

      fieldsToSearch = {
        [filterField]: keywords,
        ...fieldsToSearch,
      }
    }

    loadData({
      fields: fieldsToSearch,
      operator: operator,
      'current-page': 1,
      'record-per-page': 1000,
      'user-specific': false,
    })
  }

  const handleRecordSelect = (row) => {
    navigate(`/invoices/${row.id}/information`)
  }

  const DownloadBtn = () => {
    return (
      <>
        <ExportToExcel
          apiData={[
            ...excelRows,
            {
              Date: 'Total',
              'Invoice #': '',
              Customer: '',
              Converted: summaryData
                ? summaryData.totalConverted.replace(/,/g, '')
                : '',
              Unconverted: summaryData
                ? summaryData.totalUnconverted.replace(/,/g, '')
                : '',
              'Del Fee': summaryData
                ? summaryData.totalDeliveryFee.replace(/,/g, '')
                : '',
              Net: summaryData ? summaryData.totalNet.replace(/,/g, '') : '',
              PCT: summaryData ? summaryData.totalPct + ' %' : '',
            },
            {
              Date: 'Average',
              'Invoice #': '',
              Customer: '',
              Converted: summaryData ? summaryData?.averageConverted : '',
              Unconverted: summaryData ? summaryData?.averageUnconverted : '',
              'Del Fee': summaryData ? summaryData?.averageDeliveryFee : '',
              Net: summaryData ? summaryData?.averageNet : '',
              PCT: summaryData ? summaryData?.averagePct + ' %' : '',
            },
          ]}
          fileName={'Invoices List'}
        />
      </>
    )
  }

  const getSummary = () => {
    let totalConverted = 0
    let totalUnconverted = 0
    let totalDeliveryFee = 0
    let totalNet = 0
    let totalPct = 0

    // With Delivery Fee
    let rowsWZ = 0
    let totalConvertedWZ = 0
    let totalUnconvertedWZ = 0
    let totalDeliveryFeeWZ = 0
    let totalNetWZ = 0
    let totalPctWZ = 0

    // Without Delivery Fee
    let rowsNZ = 0
    let totalConvertedNZ = 0
    let totalUnconvertedNZ = 0
    let totalDeliveryFeeNZ = 0
    let totalNetNZ = 0
    let totalPctNZ = 0

    if (rows) {
      rows.forEach((row) => {
        let delFee = parseFloat(row['deliveryFee'].replace(/,/g, ''))

        totalDeliveryFee += delFee
        totalConverted += parseFloat(row['grandTotal'].replace(/,/g, ''))
        totalUnconverted += parseFloat(
          row['totalUnconverted'].replace(/,/g, ''),
        )
        totalNet += parseFloat(row['computationNet'].replace(/,/g, ''))
        totalPct += parseFloat(row['computationPct'].replace(/,/g, ''))

        if (delFee > 0) {
          rowsWZ++
          totalConvertedWZ += parseFloat(row['grandTotal'].replace(/,/g, ''))
          totalUnconvertedWZ += parseFloat(
            row['totalUnconverted'].replace(/,/g, ''),
          )
          totalNetWZ += parseFloat(row['computationNet'].replace(/,/g, ''))
          totalDeliveryFeeWZ += delFee
          totalPctWZ += parseFloat(row['computationPct'].replace(/,/g, ''))
        } else {
          rowsNZ++
          totalConvertedNZ += parseFloat(row['grandTotal'].replace(/,/g, ''))
          totalUnconvertedNZ += parseFloat(
            row['totalUnconverted'].replace(/,/g, ''),
          )
          totalNetNZ += parseFloat(row['computationNet'].replace(/,/g, ''))
          totalDeliveryFeeNZ += delFee
          totalPctNZ += parseFloat(row['computationPct'].replace(/,/g, ''))
        }
      })

      totalConverted = totalConverted.toFixed(2)
      totalUnconverted = totalUnconverted.toFixed(2)
      totalDeliveryFee = totalDeliveryFee.toFixed(2)
      totalNet = totalNet.toFixed(2)
      totalPct = totalPct.toFixed(2)

      totalConvertedWZ = totalConvertedWZ.toFixed(2)
      totalUnconvertedWZ = totalUnconvertedWZ.toFixed(2)
      totalDeliveryFeeWZ = totalDeliveryFeeWZ.toFixed(2)
      totalNetWZ = totalNetWZ.toFixed(2)
      totalPctWZ = totalPctWZ.toFixed(2)

      totalConvertedNZ = totalConvertedNZ.toFixed(2)
      totalUnconvertedNZ = totalUnconvertedNZ.toFixed(2)
      totalDeliveryFeeNZ = totalDeliveryFeeNZ.toFixed(2)
      totalNetNZ = totalNetNZ.toFixed(2)
      totalPctNZ = totalPctNZ.toFixed(2)
      
      let overAll = computationSummary(
        rows.length,
        totalConverted,
        totalUnconverted,
        totalDeliveryFee,
        totalNet,
        totalPct,
      );
      setSummaryData(overAll);
      
      let withDeliveryFee = computationSummary(
        rowsWZ,
        totalConvertedWZ,
        totalUnconvertedWZ,
        totalDeliveryFeeWZ,
        totalNetWZ,
        totalPctWZ,
      );
      setSummaryDataWithDelivery(withDeliveryFee);

      let withNoDeliveryFee = computationSummary(
        rowsNZ,
        totalConvertedNZ,
        totalUnconvertedNZ,
        totalDeliveryFeeNZ,
        totalNetNZ,
        totalPctNZ,
      );
      setSummaryDataNoDelivery(withNoDeliveryFee);
    }
  }

  return (
    <>
      <ResponsiveAppBar />

      {showDateRange && (
        <div
          style={{
            position: 'absolute',
            zIndex: '1000',
            backgroundColor: 'rgb(94 94 94 / 85%)',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            paddingTop: '30px',
          }}
        >
          <DateRangePicker
            ranges={[dateRange]}
            onChange={(e) => {
              setDateRange(e.selection)
            }}
          />
          <br />
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              searchHandler()
              setShowDateRange(false)
            }}
            style={{
              marginTop: '20px',
              backgroundColor: '#3675af',
              color: 'white',
              height: '55px',
              width: '300px',
            }}
          >
            Filter
          </Button>
        </div>
      )}

      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <h1>Invoices</h1>
          </Grid>
          <Grid item xs={12} md={2}>
            <div style={{ marginTop: '20px' }}>
              <Select
                options={[
                  {
                    value: 'invoice-id',
                    label: 'Invoice Number',
                    type: 'text',
                  },
                  {
                    value: 'sales-person-name',
                    label: 'Sales Person',
                    type: 'text',
                  },
                  {
                    value: 'customer-id',
                    label: 'Customer',
                    type: 'selection',
                  },
                ]}
                onChange={(e) => {
                  setFilterField(e.value)
                  setSearchType(e.type)
                }}
                placeholder="Filter by"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    background: '#fff',
                    borderColor: '#9e9e9e',
                    height: '55px',
                    boxShadow: state.isFocused ? null : null,
                  }),

                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                    padding: '0 6px',
                  }),

                  input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                  }),
                  indicatorSeparator: (state) => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '55px',
                  }),

                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            {searchType === 'text' ? (
              <FormGroup row style={{ marginTop: '20px' }}>
                <StyledTextField
                  variant="outlined"
                  placeholder="Search"
                  inputRef={searchRef}
                />
                <StyledButton
                  variant="contained"
                  disableElevation
                  onClick={() => searchHandler()}
                >
                  Search
                </StyledButton>
              </FormGroup>
            ) : (
              <>
                <div style={{ marginTop: '20px' }}>
                  <Select
                    options={
                      customers
                        ? Object.keys(customers).map((key) => {
                            return {
                              value: key,
                              label: `${customers[key]['account-number']} - ${customers[key]['company-name']}`,
                            }
                          })
                        : []
                    }
                    onChange={(e) => {
                      searchHandler(e.value)
                    }}
                    placeholder="Select Customer"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        background: '#fff',
                        borderColor: '#9e9e9e',
                        height: '55px',
                        boxShadow: state.isFocused ? null : null,
                      }),

                      valueContainer: (provided, state) => ({
                        ...provided,
                        height: '55px',
                        padding: '0 6px',
                      }),

                      input: (provided, state) => ({
                        ...provided,
                        margin: '0px',
                      }),
                      indicatorSeparator: (state) => ({
                        display: 'none',
                      }),
                      indicatorsContainer: (provided, state) => ({
                        ...provided,
                        height: '55px',
                      }),

                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </>
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                window.location.reload()
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
                marginLeft: '20px',
              }}
              fullWidth={isMobile}
            >
              <ReplayIcon />
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setShowDateRange(true)
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
                float: 'right',
              }}
              fullWidth={isMobile}
            >
              <CalendarMonthIcon />
            </Button>
          </Grid>

          <Grid item xs={12} md={1}>
            <DownloadBtn />
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                navigate('/invoices/create')
              }}
              style={{
                marginTop: '20px',
                backgroundColor: '#3675af',
                color: 'white',
                height: '55px',
              }}
              fullWidth={isMobile}
            >
              Create
            </Button>
          </Grid>
        </Grid>

        <div
          style={{
            minHeight: '80vh',
            width: '100%',
            backgroundColor: '#ffff',
            marginTop: '20px',
          }}
        >
          <div
            style={{
              maxHeight: '70vh',
            }}
          >
            <Table
              rows={rows}
              columns={columns}
              onRowClick={handleRecordSelect}
              loading={loading}
              updatePageHandler={(newPage) => updatePageHandler(newPage)}
              rowCount={pageInfoTotalRows}
              pageSize={pageInfoRowPerPage}
              pageCurrent={pageInfoCurrentPage}
            />
          </div>
          <div
            style={{
              backgroundColor: '#ffff',
              marginTop: '20px',
              paddingLeft: '20px',
              paddingBottom: '20px',
            }}
          >
            {!loading && summaryData && (
              <>
                <SummaryTableView
                  label="Total # of Invoice"
                  summaryData={{
                    totalConverted: summaryData.totalConverted,
                    totalUnconverted: summaryData.totalUnconverted,
                    totalDeliveryFee: summaryData.totalDeliveryFee,
                    totalNet: summaryData.totalNet,
                    averageConverted: summaryData.averageConverted,
                    averageUnconverted: summaryData.averageUnconverted,
                    averageDeliveryFee: summaryData.averageDeliveryFee,
                    averageNet: summaryData.averageNet,
                    averagePct: summaryData.averagePct,
                    total: summaryData.totalRows,
                  }}
                />
                <SummaryTableView
                label="With Delivery Fee - Total # of Invoice"
                summaryData={{
                  totalConverted: summaryDataWithDelivery.totalConverted,
                  totalUnconverted: summaryDataWithDelivery.totalUnconverted,
                  totalDeliveryFee: summaryDataWithDelivery.totalDeliveryFee,
                  totalNet: summaryDataWithDelivery.totalNet,
                  averageConverted: summaryDataWithDelivery.averageConverted,
                  averageUnconverted: summaryDataWithDelivery.averageUnconverted,
                  averageDeliveryFee: summaryDataWithDelivery.averageDeliveryFee,
                  averageNet: summaryDataWithDelivery.averageNet,
                  averagePct: summaryDataWithDelivery.averagePct,
                  total: summaryDataWithDelivery.totalRows,
                }}
              />
              <SummaryTableView
                label="Without Delivery Fee - Total # of Invoice"
                summaryData={{
                  totalConverted: summaryDataNoDelivery.totalConverted,
                  totalUnconverted: summaryDataNoDelivery.totalUnconverted,
                  totalDeliveryFee: summaryDataNoDelivery.totalDeliveryFee,
                  totalNet: summaryDataNoDelivery.totalNet,
                  averageConverted: summaryDataNoDelivery.averageConverted,
                  averageUnconverted: summaryDataNoDelivery.averageUnconverted,
                  averageDeliveryFee: summaryDataNoDelivery.averageDeliveryFee,
                  averageNet: summaryDataNoDelivery.averageNet,
                  averagePct: summaryDataNoDelivery.averagePct,
                  total: summaryDataNoDelivery.totalRows,
                }}
              />
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  )
}

const ExportToExcel = ({ apiData, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData)

    const columns = ['D', 'E', 'F', 'G']
    const startRow = 2

    columns.forEach((col) => {
      let row = startRow
      while (ws[col + row]) {
        ws[col + row].t = 'n' // Change the type to 'n' which stands for number.
        ws[col + row].v = parseFloat(ws[col + row].v.replace(/,/g, '')) // Convert the value to a number.
        ws[col + row].z = XLSX.SSF._table[1] // Format code for "0".
        row++
      }
    })

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <Button
      variant="contained"
      fullWidth={isMobile}
      disableElevation
      onClick={(e) => exportToCSV(apiData, fileName)}
      style={{
        marginTop: '20px',
        backgroundColor: '#3675af',
        color: 'white',
        height: '55px',
      }}
    >
      Download
    </Button>
  )
}

const SummaryTableView = ({ label, summaryData }) => {
  return (
    <div
      style={{
        borderTop: '1px solid #ccc',
      }}
    >
      <p>
        <strong>{label}:</strong> {summaryData.total}
      </p>
      <div style={{ display: '-webkit-box' }}>
        <div style={{ width: '50%' }}>
          <p>
            <strong>Total Converted:</strong> ${summaryData.totalConverted}
          </p>
          <p>
            <strong>Total Unconverted:</strong> ${summaryData.totalUnconverted}
          </p>
          <p>
            <strong>Total Delivery Fee:</strong> ${summaryData.totalDeliveryFee}
          </p>
          <p>
            <strong>Total Net:</strong> ${summaryData.totalNet}
          </p>
        </div>
        <div style={{ width: '50%' }}>
          <p>
            <strong>Average Converted:</strong> ${summaryData.averageConverted}
          </p>
          <p>
            <strong>Average Unconverted:</strong> $
            {summaryData.averageUnconverted}
          </p>
          <p>
            <strong>Average Delivery Fee:</strong> $
            {summaryData.averageDeliveryFee}
          </p>
          <p>
            <strong>Average Net:</strong> ${summaryData.averageNet}
          </p>
          <p>
            <strong>Average PCT:</strong>
            {summaryData.averagePct} %
          </p>
        </div>
      </div>
    </div>
  )
}

const computationSummary = (
  dataTotal,
  totalConverted,
  totalUnconverted,
  totalDeliveryFee,
  totalNet,
  totalPct,
) => {
  let averageConverted = 0
  let averageUnconverted = 0
  let averageDeliveryFee = 0
  let averageNet = 0
  let averagePct = 0

  if (dataTotal > 0) {
    averageConverted = totalConverted / dataTotal
    averageUnconverted = totalUnconverted / dataTotal
    averageDeliveryFee = totalDeliveryFee / dataTotal
    averageNet = totalNet / dataTotal
    averagePct = (totalNet / totalUnconverted) * 100

    averageConverted = averageConverted.toFixed(2)
    averageUnconverted = averageUnconverted.toFixed(2)
    averageDeliveryFee = averageDeliveryFee.toFixed(2)
    averageNet = averageNet.toFixed(2)
    averagePct = averagePct.toFixed(2)

    averageConverted = averageConverted
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    averageUnconverted = averageUnconverted
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    averageDeliveryFee = averageDeliveryFee
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    averageNet = averageNet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    averagePct = averagePct.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  totalConverted = totalConverted
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  totalUnconverted = totalUnconverted
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  totalDeliveryFee = totalDeliveryFee
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  totalNet = totalNet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  totalPct = totalPct.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return {
    totalConverted: totalConverted,
    totalUnconverted: totalUnconverted,
    totalDeliveryFee: totalDeliveryFee,
    totalNet: totalNet,
    totalPct: totalPct,
    averageConverted: averageConverted,
    averageUnconverted: averageUnconverted,
    averageDeliveryFee: averageDeliveryFee,
    averageNet: averageNet,
    averagePct: averagePct,
    totalRows: dataTotal,
  }
}

export default App
